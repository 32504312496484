import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "select-action-box",
  templateUrl: "./select-action-box.component.html",
  styleUrls: ["./select-action-box.component.scss"],
})
export class SelectActionBoxComponent implements OnInit {
  @Input() name: string;
  @Input() selectItems: any;
  @Input() control: FormControl;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
