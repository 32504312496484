import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-popover-password-check",
  templateUrl: "./popover-password-check.component.html",
  styleUrls: ["./popover-password-check.component.scss"],
})
export class PopoverPasswordCheckComponent implements OnInit {
  @Input() show: boolean;
  @Input() passwordCheck: any;
  constructor() {}

  ngOnInit() {}
}
