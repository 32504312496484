import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-select-circles",
  templateUrl: "./select-circles.component.html",
  styleUrls: ["./select-circles.component.scss"],
})
export class SelectCirclesComponent implements OnInit {
  @Input() values: Array<string>;
  @Input() index: number;
  @Output() toIndex?: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onSetIndex(index) {
    this.toIndex.emit({ index });
  }
}
