import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "select-item-action-box",
  templateUrl: "./select-item-action-box.component.html",
  styleUrls: ["./select-item-action-box.component.scss"],
})
export class SelectItemActionBoxComponent implements OnInit {
  @Input() name: string;
  @Input() selectItems: any;
  @Input() selectedItem: any;
  @Input() control: FormControl;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter(true);
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Input() placeholder: string;
  public show: string; // ADD, EDIT, VIEW

  constructor() {}

  ngOnInit() {
    this.show = this.selectedItem ? "VIEW" : "ADD";
  }

  onDelete() {
    this.show = "ADD";
    this.delete.emit();
  }

  onSave() {
    this.show = "VIEW";
    this.save.emit();
  }

  onEdit() {
    this.show = "EDIT";
  }
}
