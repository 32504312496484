export const externalSystemsConfig = [
  {
    name: "Shopify",
    hasCategories: true,
    hasTags: false,
    properties: [
      {
        name: "Username",
        input: true,
        display: true,
        hide: false,
        label: "ShopifyShop",
        caption: "ShopifyBuisinessNameCaption",
        formName: "name",
      },
    ],
    func: "linkShopifyConnection",
  },
  {
    name: "WooCommerce",
    hasCategories: true,
    hasTags: true,
    properties: [
      {
        name: "Username",
        input: true,
        display: true,
        hide: false,
        label: "WooCommerceUsernameShort",
        caption: "WooCommerceUsername",
        formName: "name",
      },
      {
        name: "Password",
        input: true,
        display: false,
        hide: true,
        label: "WooCommercePasswordShort",
        caption: "WooCommercePassword",
        formName: "password",
      },
      {
        name: "CustomEndpoint",
        input: true,
        display: true,
        hide: false,
        label: "URL",
        caption: "WooCommerceURL",
        formName: "url",
      },
    ],
    func: null,
  },
  {
    name: "Korona",
    hasCategories: false,
    hasTags: false,
    properties: [
      {
        name: "Username",
        input: true,
        display: true,
        hide: false,
        label: "Username",
        caption: "KoronaUsername",
        formName: "name",
      },
      {
        name: "Password",
        input: true,
        display: false,
        hide: true,
        label: "Password",
        caption: "KoronaPassword",
        formName: "password",
      },
      {
        name: "ExternalId",
        input: true,
        display: true,
        hide: false,
        label: "AccountID",
        caption: "KoronaExternalId",
        formName: "externalId",
      },
      {
        name: "CustomEndpoint",
        input: true,
        display: true,
        hide: false,
        label: "URL",
        caption: "KoronaURL",
        formName: "url",
      },
    ],
    func: null,
  },
  {
    name: "Smartstore",
    hasCategories: false,
    hasTags: false,
    properties: [
      {
        name: "ApiKey",
        input: true,
        display: true,
        hide: false,
        label: "Api-Key",
        caption: "SmartstoreApiKey",
        formName: "api",
      },
      {
        name: "CustomEndpoint",
        input: true,
        display: true,
        hide: false,
        label: "URL",
        caption: "SmartstoreURL",
        formName: "url",
      },
    ],
    func: null,
  },
  {
    name: "EPages",
    hasCategories: false,
    hasTags: false,
    properties: [
      {
        name: "CustomEndpoint",
        input: false,
        display: true,
        hide: false,
        label: "URL",
        caption: "EPagesURL",
        formName: "url",
      },
    ],
    func: null,
  },
];
