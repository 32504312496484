import { Injectable, inject } from "@angular/core";
import { WINDOW_PROVIDERS } from "../../../../shared/helpers/window.helper";
import { HttpClient } from "@angular/common/http";
import { CrudService } from "app/views/mapAds/crud.service";
import { MatDialog, MatDialogRef, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

declare var FB: any;
@Injectable({
  providedIn: "root",
})
export class FbService {
  storageKey = "fb_token";
  userStorageKey = "user_storage_token";

  defaultPermissions = [
    "public_profile",
    "pages_read_engagement",
    "ads_read",
    "business_management",
    "pages_manage_posts",
    "instagram_basic",
    "ads_management",
    "pages_read_user_content",
  ];

  public assignedPermissions: string[] = [];

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.init();
    this.assignedPermissions = this.defaultPermissions;
  }

  init() {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: "2643088195951457",
        cookie: true,
        xfbml: true,
        version: "v14.0",
      });

      FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    //this.GetPermissions();
  }

  public async login() {
    return new Promise(async (resolve, reject) => {
      this.assignedPermissions = await this.crudService
        .GetAllPermissions()
        .toPromise();

      const isRegistered = await this.crudService
        .CheckFacebookRegisteredUser()
        .toPromise();

      if (!isRegistered) {
        if (FB != undefined) {
          FB.getLoginStatus(async (response) => {
            if (response) {
              await this.LoginProcedure(isRegistered)
                .then((res) => {
                  resolve(true);
                })
                .catch((e) => {
                  reject(e);
                });
            } else {
              reject(this.translate.instant("errorOccured"));
            }
          });
        } else {
          reject(this.translate.instant("errorOccured"));
        }
      } else {
        await this.LoginProcedure(isRegistered)
          .then((res) => {
            if (res) {
              resolve(true);
            }
          })
          .catch((e) => {
            reject(e);
          });
      }
    });

    // const superX = this;

    // await this.crudService.GetAllPermissions().subscribe((x) => {
    //   this.assignedPermissions = x;

    //   // superX.LoginProcedure(false);
    //   // check if it is already logged in or not
    //   this.crudService.CheckFacebookRegisteredUser().subscribe(
    //     (isRegistered) => {
    //       if (!isRegistered) {
    //         if (FB != undefined) {
    //           FB.getLoginStatus((response) => {
    //             console.log("getLoginStatus response", response);
    //             if (response) {
    //               //go with the login procedure
    //               superX.LoginProcedure(isRegistered);
    //             } else {
    //               //do nothing?
    //               //show a snackbar that you are already registered ?
    //               this.snackBar.open("Untracked Path", "OK", {
    //                 duration: 4000,
    //               });
    //             }
    //           });
    //         }
    //       } else {
    //         superX.LoginProcedure(isRegistered);
    //       }
    //     },
    //     (err) => {
    //       this.snackBar.open("Überprüfung fehlgeschlagen", "OK", {
    //         duration: 4000,
    //       });
    //     }
    //   );
    // });
  }

  private LoginProcedure(isRegistered: boolean) {
    // if (!isRegistered) {
    return new Promise(async (resolve, reject) => {
      FB.login(
        async (response) => {
          if (response.authResponse) {
            window.localStorage.setItem(this.storageKey, FB);

            await this.InitializeNewFacebookUser(
              response.authResponse.accessToken,
              isRegistered
            )
              .then((res) => {
                resolve(true);
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            reject(this.translate.instant("logInFailure"));
          }
        },
        { scope: this.assignedPermissions }
      );
    });

    // } else {

    //   //Update data

    //   this.snackBar.open("Daten aktualisiert.", "OK", { duration: 4000 });
    // }
  }
  // private GetAllPermissionsString(): string {
  //   return this.assignedPermissions.map((x) => x.name).join(",");
  // }
  // private GetPermissionsString(): string {
  //   return this.assignedPermissions
  //     .filter((x) => x.isAssigned == true)
  //     .map((x) => x.name)
  //     .join(",");
  // }

  // public async GetPermissions() {
  //   await this.crudService.GetAllPermissions().subscribe((x) => {
  //     this.assignedPermissions = x;
  //     console.log("qwe123");
  //   });
  // }

  // public UpdateToken() {
  //   FB.login(
  //     (response) => {
  //       if (response.authResponse) {
  //         window.localStorage.setItem(this.storageKey, FB);

  //         // the update api should work for it
  //         this.crudService
  //           .UpdateFacebookUserToken(response.authResponse.accessToken)
  //           .subscribe(
  //             (x) => {
  //               this.snackBar.open("Aktualisierung erfolgreich", "OK", {
  //                 duration: 4000,
  //               });
  //               window.location.reload();
  //             },
  //             (err) => {
  //               this.snackBar.open(
  //                 "Aktualisieurng fehlgeschlagen: " + err.message,
  //                 "OK",
  //                 { duration: 4000 }
  //               );
  //             }
  //           );
  //       } else {
  //         this.snackBar.open("Login fehlgeschlagen", "OK", { duration: 4000 });
  //       }
  //     },
  //     { scope: this.GetAllPermissionsString() }
  //   );
  // }

  // public PublishPostFacebook(row) {
  //   let body = {
  //     promoId: row.promo_id,
  //   };
  //   this.crudService.CheckFacebookRegisteredUser().subscribe(
  //     (resp) => {
  //       if (resp) {
  //         this.crudService.SendPromoToFacebookPage(body).subscribe(
  //           (data) => {
  //             this.snackBar.open(
  //               "Das Produkt wurde erfolgreich zu deiner Facebook-Seite geschickt.",
  //               "OK",
  //               { duration: 3000 }
  //             );
  //           },
  //           (err) => {
  //             this.snackBar.open(
  //               "Das Produkt konnte nicht zur Facebook-Seite geschickt werden.",
  //               "OK",
  //               { duration: 3000 }
  //             );
  //           }
  //         );
  //       } else {
  //         //show how to register for facebook
  //         this.snackBar.open(
  //           "Du bist noch mit keinem Facebook-Konto verknüpft. Um deinen Account zu verbinden, geh einfach zu den Einstellungen.",
  //           "",
  //           { duration: 5000 }
  //         );
  //       }
  //     },
  //     (err) => {}
  //   );
  // }

  // public PublishAdsFacebook(row) {
  //   let body = {
  //     promoId: row.promo_id,
  //   };
  //   this.crudService.CheckFacebookRegisteredUser().subscribe(
  //     (resp) => {
  //       if (resp) {
  //         this.crudService.CreateFacebookAd(row.promo_id).subscribe(
  //           (data) => {
  //             this.snackBar.open(
  //               "Die Facebook-Werbung wurde erfolgreich erstellt.",
  //               "OK",
  //               { duration: 3000 }
  //             );
  //           },
  //           (err) => {
  //             this.snackBar.open(
  //               "Die Facebook-Werbung konnte nicht erstellt werden.",
  //               "OK",
  //               { duration: 3000 }
  //             );
  //           }
  //         );
  //       } else {
  //         //show how to register for facebook
  //         this.snackBar.open(
  //           "Du bist noch mit keinem Facebook-Konto verknüpft. Um deinen Account zu verbinden, geh einfach zu den Einstellungen.",
  //           "",
  //           { duration: 5000 }
  //         );
  //       }
  //     },
  //     (err) => {}
  //   );
  // }

  public CheckLoginStatus() {
    if (FB != undefined) {
      FB.getLoginStatus(function (response) {
        this.statusChangeCallback(response);
      });
    }
  }

  private InitializeNewFacebookUser(userToken: string, isrefresh: boolean) {
    return new Promise(async (resolve, reject) => {
      if (userToken != "" && userToken != null) {
        await this.crudService
          .InitializeFacebookUser(userToken)
          .toPromise()
          .then((res) => {
            const message = isrefresh
              ? this.translate.instant("dataUpdatedSuccessfully")
              : this.translate.instant("loggedInSuccessfully");

            this.snackBar.open(message, "OK", {
              duration: 4000,
            });

            resolve(true);
          })
          .catch((e) => {
            reject(e.error);
          });
      } else {
        reject(this.translate.instant("noAvailableToken"));
      }
    });
    // if (userToken != "" && userToken != null) {
    //   //send the token to backend
    //   this.crudService.InitializeFacebookUser(userToken).subscribe(
    //     (res) => {
    //       // Ask the user to chose facebook pages
    //       if (isrefresh) {
    //         this.snackBar.open("Daten aktualisiert.", "OK", { duration: 4000 });
    //       } else {
    //         this.snackBar.open("Erfolgreich angemeldet.", "OK", {
    //           duration: 4000,
    //         });
    //       }
    //       // window.location.reload();
    //     },
    //     (err) => {
    //       this.snackBar.open(
    //         "Daten konnten nicht initialisiert werden.",
    //         "OK",
    //         { duration: 4000 }
    //       );
    //     }
    //   );
    // } else {
    //   //show that there is no token
    //   this.snackBar.open("Es ist kein Nutzertoken verfügbar.", "OK", {
    //     duration: 4000,
    //   });
    // }
  }

  SyncUser(accessToken = null) {
    return this.crudService.SyncFacebookUser(accessToken);
  }

  // openSnackBar(message: string, action: string) {
  //   this.snackBar.open(message, action, {
  //     duration: 4000,
  //   });
  // }

  // private OpenPopup() {
  //   this.crudService.GetMyFacebookPages().subscribe(fbPagedata => {

  //     this.crudService.GetFacebookStorePageTree().subscribe(fbStoreTreedata => {
  //       this.crudService.GetFacebookUserInformation().subscribe(fbUserData => {

  //         let dialogRef: MatDialogRef<any> = this.dialog.open(FacebookPageConnectPopupComponent, {
  //           maxHeight: "70vh", width: '70vw', data: {
  //             pageData: fbPagedata,
  //             userData: fbUserData,
  //             storeTreeData: fbStoreTreedata
  //           }
  //         });
  //       }, err => {
  //         this.openSnackBar("Die Facebook-Nutzerinformationen konnten nicht gelesen werden: " + err.message, "OK");
  //       });
  //     }, err => {
  //       this.openSnackBar("Geschäfte konnten nicht geladen werden: " + err.message, "OK");
  //     });
  //   }, err => {
  //     this.openSnackBar("Facebook-Seiten konnten nicht geladne werden: " + err.message, "OK");

  //   });
  // }
}
