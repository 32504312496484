import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SupportPopoverComponent } from '../../dialogs/support-popover/support-popover.component';

@Component({
  selector: 'app-support-popover-button',
  templateUrl: './support-popover-button.component.html',
  styleUrls: ['./support-popover-button.component.scss']
})
export class SupportPopoverButtonComponent implements OnInit {

  constructor(private dialog: MatDialog,) { }

  ngOnInit() {
  }

  openSupportPopover() {
    const dialogRef = this.dialog.open(SupportPopoverComponent, {
      width: "1000px",
    });
  }

}
