import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "store-location-select-list",
  templateUrl: "./store-location-select-list.component.html",
  styleUrls: ["./store-location-select-list.component.scss"],
})
export class StoreLocationSelectListComponent implements OnInit {
  @Input() stores: any;
  @Output() onSelectStore: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
