import { Injectable } from "@angular/core";

export class ImageService {
  public image: any;
  constructor(image) {
    this.image = image;
  }

  public isTransparent() {
    return new Promise((resolve, reject) => {
      let hasAlpha = false;
      const img = new Image();
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      img.crossOrigin = "Anonymous";
      img.onerror = reject;
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);
        const imgData = ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        ).data;

        for (let j = 0; j < imgData.length; j += 4) {
          if (imgData[j + 3] < 255) {
            hasAlpha = true;
            break;
          }
        }
        resolve(hasAlpha);
      };
      img.src = URL.createObjectURL(this.image);
    });
  }
}
