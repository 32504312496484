import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SUPPORT_EMAIL_ADDRESS, SUPPORT_PHONE_NUMBER } from "../../../../../constants";

@Component({
  selector: 'app-support-popover',
  templateUrl: './support-popover.component.html',
  styleUrls: ['./support-popover.component.scss']
})
export class SupportPopoverComponent implements OnInit {
  readonly SUPPORT_EMAIL_ADDRESS = SUPPORT_EMAIL_ADDRESS;
  readonly SUPPORT_PHONE_NUMBER = SUPPORT_PHONE_NUMBER;

  constructor(public dialogRef: MatDialogRef<SupportPopoverComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }

  onClose(): void {
    this.dialogRef.close();
  }

}
