import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import ConfettiGenerator from "confetti-js";
import { trigger, style, animate, transition } from "@angular/animations";

@Component({
  selector: "success-trophy-message",
  templateUrl: "./success-trophy-message.component.html",
  styleUrls: ["./success-trophy-message.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "scale(0.3)" }),
        animate(
          "750ms cubic-bezier(0, 0, 0.2, 1)",
          style({ opacity: 1, transform: "scale(1.1)" })
        ),
        animate(
          "500ms cubic-bezier(0, 0, 0.2, 1)",
          style({ transform: "scale(1)" })
        ),
      ]),
    ]),
  ],
})
export class SuccessTrophyMessageComponent implements OnInit {
  @Input() title: string;
  @Output() onNext: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {
    var confettiSettings = { target: "confetti-container" };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }
}
