import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ProductOverview } from "app/model/products/ProductOverview";
import { CurrencyService } from "app/shared/services/currency.service";

@Component({
  selector: "youtube-account-information",
  templateUrl: "./youtube-account-information.component.html",
  styleUrls: ["./youtube-account-information.component.scss"],
})
export class YoutubeAccountInformationComponent implements OnInit {
  @Input() budgetSummary: any;
  @Input() productOverview: ProductOverview;
  public AtLeast300BudgetForVideoAdvertising: string;
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.AtLeast300BudgetForVideoAdvertising = this.translateService.instant(
      "AtLeastBudgetForVideoAdvertising",
      { amount: CurrencyService.formatNumber2Price(300) }
    );
  }
}
