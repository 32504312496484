import { Component, OnInit } from "@angular/core";

@Component({
  selector: "youtube-service-info-guide",
  templateUrl: "./youtube-service-info-guide.component.html",
  styleUrls: ["./youtube-service-info-guide.component.scss"],
})
export class YoutubeServiceInfoGuideComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
