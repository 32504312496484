import { Component, OnInit } from "@angular/core";

@Component({
  selector: "google-service-info-guide",
  templateUrl: "./google-service-info-guide.component.html",
  styleUrls: ["./google-service-info-guide.component.scss"],
})
export class GoogleServiceInfoGuideComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
