import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "heat-map-settings",
  templateUrl: "./heat-map-settings.component.html",
  styleUrls: ["./heat-map-settings.component.scss"],
})
export class HeatMapSettingsComponent implements OnInit {
  @Input() locationsStatistics: any;
  @Input() useGoogleStatistics: boolean;
  @Input() useYoutubeStatistics: boolean;
  @Output() onSelectChannel: EventEmitter<any> = new EventEmitter();
  public channels: any;
  constructor() {
    this.channels = [];
  }

  ngOnInit() {
    this.initChannels();
  }

  initChannels() {
    this.locationsStatistics.forEach((locationsStatistic) => {
      if (locationsStatistic.ChannelName === "Google") {
        this.channels.push({
          name: locationsStatistic.ChannelName,
          isActive: this.useGoogleStatistics,
        });
      }

      if (locationsStatistic.ChannelName === "Youtube") {
        this.channels.push({
          name: locationsStatistic.ChannelName,
          isActive: this.useYoutubeStatistics,
        });
      }
    });
  }

  channelToggle($event, channelName) {
    this.onSelectChannel.emit({ channelName, checked: $event.checked });
  }
}
