export class ProductChannelInformation {
  Id: string;
  Name: string;
  Description: string;
  Link: string;
  Example: string;
  MinBudget: number;
  FactorReach: number;
  isEnabled: boolean;
  isActive: boolean;
  ChannelId: number;
  Icon: string;
  isChannelEnabled: true;
}
