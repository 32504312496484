import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "content-box-item",
  templateUrl: "./content-box-item.component.html",
  styleUrls: ["./content-box-item.component.scss"],
})
export class ContentBoxItemComponent implements OnInit {
  @Input() divider?: boolean;
  @Input() spacing?: string = "4";
  constructor() {}

  ngOnInit() {}
}
