import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-confirm",

  template: `<h1 matDialogTitle class="mb-05" style="text-align: center;">
      {{ data.title }}
    </h1>
    <div mat-dialog-content class="margin:5px" [innerHTML]="data.message"></div>
    <div style="margin-top: 20px;">
      <button
        type="button"
        color="accent"
        mat-raised-button
        (click)="dialogRef.close(false)"
        style="width:48%"
      >
        {{ "cancel" | translate }}
      </button>
      &nbsp;
      <span style="width:2%"></span>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(true)"
        style="width:48%"
      >
        {{ "Okay" | translate }}
      </button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
