import { Component, Input, OnInit } from "@angular/core";
import { ExternalSystem } from "app/views/mapAds/external-systems/external-systems.component";

@Component({
  selector: "app-active-external-systems-grid",
  templateUrl: "./active-external-systems-grid.component.html",
  styleUrls: ["./active-external-systems-grid.component.scss"],
})
export class ActiveExternalSystemsGridComponent implements OnInit {
  @Input() externalSystems: ExternalSystem[];
  constructor() {}

  ngOnInit() {}
}
