import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-progress-circles",
  templateUrl: "./progress-circles.component.html",
  styleUrls: ["./progress-circles.component.scss"],
})
export class ProgressCirclesComponent implements OnInit {
  @Input() currentIndex: number;
  @Input() values: Array<string>;
  @Input() showLabel: boolean = false;

  constructor() {}

  ngOnInit() {}
}
