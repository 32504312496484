import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { SubChannelDetails } from "app/model/channel/SubChannelDetails";

@Component({
  selector: "app-channel-info-dialog",
  templateUrl: "./channel-info-dialog.component.html",
  styleUrls: ["./channel-info-dialog.component.scss"],
})
export class ChannelInfoDialogComponent implements OnInit {
  public showVideo: boolean;
  public safeVideoURL: any;
  constructor(
    public dialogRef: MatDialogRef<ChannelInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.showVideo = this.hasVideoSource();

    if (this.showVideo) {
      this.safeVideoURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.data.subChannel.Example
      );
    }
  }

  hasVideoSource() {
    const subChannel: SubChannelDetails = this.data.subChannel;
    return (
      subChannel.Example &&
      (subChannel.Example.includes(".mp4") ||
        subChannel.Example.includes(".mov"))
    );
  }

  onClose() {
    this.dialogRef.close();
  }
}
