import { CrudService } from "./crud.service";
import { HttpClient } from "@angular/common/http";
import { combineLatest, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

export class FileSystem {
  public id: number = 0;
  public myFile: any;
  public isSuccess: boolean;
  public isUploading: boolean;
  public isReady: boolean;
  public uploaded: boolean;
  public uploadError: boolean;

  constructor(
    file: File,
    index: number = 0,
    isSuccess: boolean = false,
    isUploading: boolean = false,
    isReady: boolean = false,
    uploaded: boolean = false
  ) {
    this.id = index;
    this.myFile = file;
    this.isReady = isReady;
    this.isSuccess = isSuccess;
    this.isUploading = isUploading;
    this.uploaded = uploaded;
  }
}

export class CustomFileUploader {
  public files: Array<FileSystem> = null;
  public crudService: CrudService;
  public uploadedAll: boolean = false;
  public uploadingAll: boolean = false;

  constructor(private httpClient: HttpClient) {
    this.crudService = new CrudService(httpClient);
  }

  public upload(index: number = null): Array<Observable<any>> {
    var len = 0;
    var listofObservables = [];
    var listOfIds = [];

    if (index != null && index >= 0) {
      len = 1;
      this.files[index].isUploading = true;

      const isTransparent =
        this.files[index].myFile.hasOwnProperty("isTransparent") &&
        this.files[index].myFile.isTransparent
          ? true
          : false;
      var uploadingCat = this.crudService.UploadFile(
        this.files[index].myFile,
        isTransparent
      );
      listofObservables.push(uploadingCat);
    } else {
      this.uploadingAll = true;

      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].id == null || this.files[i].id == 0) {
          this.files[i].isUploading = true;
          const isTransparent =
            this.files[i].myFile.hasOwnProperty("isTransparent") &&
            this.files[i].myFile.isTransparent
              ? true
              : false;
          var uploadingCat = this.crudService.UploadFile(
            this.files[i].myFile,
            isTransparent
          );
          listofObservables.push(uploadingCat);
        }
      }
    }
    return listofObservables;
  }

  public add(myfile: File) {
    if (this.files == null) this.files = [];
    var fs = new FileSystem(myfile);

    this.files.push(fs);
    this.uploadedAll = false;
  }

  public addRange(files: Array<File>) {
    if (this.files == null) this.files = [];

    for (var i = 0; i < files.length; i++) {
      var fs = new FileSystem(files[i]);
      //fs.isReady=true;
      this.files.push(fs);
    }
    this.uploadedAll = false;
  }
  public remove(index: number) {
    this.files.splice(index, 1);
  }
}
