import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { dataURLtoFile } from "app/shared/utils/blob.utils";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-crop-image-dialog",
  templateUrl: "./crop-image-dialog.component.html",
  styleUrls: ["./crop-image-dialog.component.scss"],
})
export class CropImageDialogComponent implements OnInit {
  public isLoading: boolean;
  public imageFile: any;
  public croppedImage: any;
  public maintainAspectRatio: boolean;
  constructor(
    public dialogRef: MatDialogRef<CropImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isLoading = true;
    this.maintainAspectRatio = true;
  }

  ngOnInit() {
    this.imageFile = this.data.file;
  }

  imageLoaded() {
    this.isLoading = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    let el = document.getElementById("preview-cropped-image");
    if (event.height > event.width) {
      el.style.width = "auto";
      el.style.height = "128px";
    } else {
      el.style.width = "128px";
      el.style.height = "auto";
    }

    this.croppedImage = event.base64;
  }

  cropperReady() {
    this.maintainAspectRatio = false;
  }

  onApply() {
    const fileName = this.data.file.name;
    const file = dataURLtoFile(this.croppedImage, fileName);

    this.dialogRef.close({ res: file });
  }

  onClose() {
    this.dialogRef.close();
  }
}
