import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "trend-arrow",
  templateUrl: "./trend-arrow.component.html",
  styleUrls: ["./trend-arrow.component.scss"],
})
export class TrendArrowComponent implements OnInit {
  @Input() trend: string = "up";
  constructor() {}

  ngOnInit() {}
}
