export class SubChannelSelection {
  public googleMyBusiness: boolean;
  public googleLocalInventory: boolean;
  public facebookPosts: boolean;
  public facebookAds: boolean;
  public facebookVideoAds: boolean;
  public instagramAds: boolean;
  public instagramVideoAds: boolean;
  public youtubeVideoAds: boolean;
}
