import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "channel-preferences-panel-card",
  templateUrl: "./channel-preferences-panel-card.component.html",
  styleUrls: ["./channel-preferences-panel-card.component.scss"],
})
export class ChannelPreferencesPanelCardComponent implements OnInit {
  @Input() name: String;
  @Input() value: Boolean;
  @Input() logo: String;
  @Input() status: any;
  @Input() minBudget: Number;
  @Input() budgetSummary: any;
  @Input() hideIcon: boolean = false;
  constructor() {}

  ngOnInit() {}
}
