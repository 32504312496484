import { Component, OnInit, Input } from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { RoutePartsService } from "app/shared/services/route-parts.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public routeParts: any[];
  public routerEventSub: Subscription;
  public pageTitle: string = null;

  public availableLangs = [
    {
      name: "DE",
      code: "de",
      flag: "flag-icon-de",
    },
    {
      name: "EN",
      code: "en",
      flag: "flag-icon-gb",
    },
  ];
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private routePartsService: RoutePartsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.routerEventSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        this.routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );

        this.routeParts.reverse().map((item, i) => {
          this.pageTitle = item.title;
        });
      });
  }

  ngOnInit() {
    this.pageTitle = this.title
      .getTitle()
      .split(">")
      .pop()
      .split("|")[0]
      .trim();

    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
  }

  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === "compact") {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: "full",
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: "compact",
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  signOut() {
    localStorage.clear();
    this.router.navigate(["/sessions/signin"]);
  }
}
