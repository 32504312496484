import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ProductChannelInformation } from "app/model/ProductChannelInformation";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-promotion-successfully-created-dialog",
  templateUrl: "./promotion-successfully-created-dialog.component.html",
  styleUrls: ["./promotion-successfully-created-dialog.component.scss"],
})
export class PromotionSuccessfullyCreatedDialogComponent implements OnInit {
  isLoading: boolean;
  channels: ProductChannelInformation[];

  constructor(
    public dialogRef: MatDialogRef<PromotionSuccessfullyCreatedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService
  ) {
    this.isLoading = true;
  }

  async ngOnInit() {
    this.channels = await this.getChannels();
    this.channels = this.channels.filter((channel) => channel.isChannelEnabled);

    this.isLoading = false;
  }

  onClose() {
    this.dialogRef.close();
  }

  getChannels() {
    return this.crudService
      .GetProductChannelInformation(this.data.promoId)
      .toPromise();
  }
}
