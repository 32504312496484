import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "trend-value",
  templateUrl: "./trend-value.component.html",
  styleUrls: ["./trend-value.component.scss"],
})
export class TrendValueComponent implements OnInit {
  @Input() value: number;
  @Input() size?: number = 1;
  public math = Math;
  constructor() {}

  ngOnInit() {}
}
