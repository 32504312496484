import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "error-icon-message",
  templateUrl: "./error-icon-message.component.html",
  styleUrls: ["./error-icon-message.component.scss"],
})
export class ErrorIconMessageComponent implements OnInit {
  @Input() message: string;
  constructor() {}

  ngOnInit() {}
}
