import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "future-channel-history-card",
  templateUrl: "./future-channel-history-card.component.html",
  styleUrls: ["./future-channel-history-card.component.scss"],
})
export class FutureChannelHistoryCardComponent implements OnInit {
  @Input() futureChannelHistoryItem: any;
  constructor() {}

  ngOnInit() {}
}
