import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "no-data-message",
  templateUrl: "./no-data-message.component.html",
  styleUrls: ["./no-data-message.component.scss"],
})
export class NoDataMessageComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  constructor() {}

  ngOnInit() {}
}
