import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DATE_FORMAT_ENGLISH, DATE_FORMAT_GERMAN } from "../../../constants";
import * as moment from "moment";

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(date: Date | string, timestamp: boolean = false): string {
    let dateFormat: string = this.getDateFormat();

    return moment(date).format(dateFormat);
  }

  getDateFormat() {
    return this.translate.currentLang === "de"
      ? DATE_FORMAT_GERMAN
      : DATE_FORMAT_ENGLISH;
  }
}
