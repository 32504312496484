import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getStoreNameById",
})
export class GetStoreNameByIdPipe implements PipeTransform {
  transform(value: number, arg: any): string {
    let store = arg.find((item) => item.storeId === value);

    return store ? store.storeName : "";
  }
}
