import { Component, Input, OnInit } from "@angular/core";
import { ExternalSystem } from "app/views/mapAds/external-systems/external-systems.component";
@Component({
  selector: "app-external-system-card",
  templateUrl: "./external-system-card.component.html",
  styleUrls: ["./external-system-card.component.scss"],
})
export class ExternalSystemCardComponent implements OnInit {
  @Input() externalSystem: ExternalSystem;
  constructor() {}

  ngOnInit() {}
}
