import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectMenu } from "app/model/SelectMenu";

@Component({
  selector: "select-menu",
  templateUrl: "./select-menu.component.html",
  styleUrls: ["./select-menu.component.scss"],
})
export class SelectMenuComponent implements OnInit {
  @Input() menu: SelectMenu[];
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
