import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-google-storecodes-instructions",
  templateUrl: "./google-storecodes-instructions.component.html",
  styleUrls: ["./google-storecodes-instructions.component.scss"],
})
export class GoogleStorecodesInstructionsComponent implements OnInit {
  @ViewChild("videoPlayer") videoplayer: ElementRef;
  constructor() {}

  ngOnInit() {}

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
}
