import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Pipe({ name: "relativeTime" })
export class RelativeTimePipe implements PipeTransform {
  constructor() {}
  transform(value: Date | string) {
    if (!(value instanceof Date)) value = new Date(value);
    return moment(value).fromNow();
  }
}
