import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getTemplateNameById",
})
export class GetTemplateNameByIdPipe implements PipeTransform {
  transform(value: any, arg?: any): any {
    let template = arg.find((item) => item.Id === value);

    return template ? template.Name : "";
  }
}
