import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "error-action-message",
  templateUrl: "./error-action-message.component.html",
  styleUrls: ["./error-action-message.component.scss"],
})
export class ErrorActionMessageComponent implements OnInit {
  @Input() message: string;
  @Input() btnLabel: string;
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
