import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "image-background-remover",
  templateUrl: "./image-background-remover.component.html",
  styleUrls: ["./image-background-remover.component.scss"],
})
export class ImageBackgroundRemoverComponent implements OnInit {
  @Input() imageFile: any;
  @Input() backgroundRemovalTries: number;
  constructor() {}

  ngOnInit() {}
}
