import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "add-action-box",
  templateUrl: "./add-action-box.component.html",
  styleUrls: ["./add-action-box.component.scss"],
})
export class AddActionBoxComponent implements OnInit {
  @Input() text: string;
  constructor() {}

  ngOnInit() {}
}
