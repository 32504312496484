import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram-preview',
  templateUrl: './instagram-preview.component.html',
  styleUrls: ['./instagram-preview.component.scss']
})
export class InstagramPreviewComponent implements OnInit {
  @Input() data: any
  public storeName: string = window.localStorage["storeName"];
  public storeImage: string = window.localStorage["StoreUrl"]

  constructor() { }

  ngOnInit() {
  }

}
