import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "custom-action-icon-message",
  templateUrl: "./custom-action-icon-message.component.html",
  styleUrls: ["./custom-action-icon-message.component.scss"],
})
export class CustomActionIconMessageComponent implements OnInit {
  @Input() type: string;
  @Input() btnLabel: string;
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
