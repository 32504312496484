import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-select-arrow",
  templateUrl: "./select-arrow.component.html",
  styleUrls: ["./select-arrow.component.scss"],
})
export class SelectArrowComponent implements OnInit {
  @Input() values: Array<string>;
  @Input() index: number;
  @Output() toIndex?: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
