import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CLIENT_ID } from "../../../../constants";
import { environment } from "../../../../environments/environment";

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(
    private router: Router,
    private httpclient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage["token"] == null) this.isAuthenticated = false;
    else this.isAuthenticated = true;

    //Expired??
    if (new Date(localStorage["expiry"]) < new Date()) {
      localStorage["token"] = null;
      this.isAuthenticated = false;
      if (
        localStorage["rememberme"] != null &&
        localStorage["rememberme"] == "true"
      ) {
        //try to refresh the token!!
        if (localStorage["refreshtoken"] != null) {
          await this.refreshToken()
            .then((result) => {
              if (result.access_token == null) {
                this.isAuthenticated = false;
              } else {
                window.localStorage["token"] = result.access_token;
                window.localStorage["refreshtoken"] = result.refresh_token;
                window.localStorage["expiry"] = new Date(
                  new Date().getTime() + result.expires_in * 1000
                );
                this.isAuthenticated = true;
              }
            })
            .catch((error) => {
              this.isAuthenticated = false;
            });

          if (localStorage["token"] != null) {
            this.isAuthenticated = true;
          }
        }
      }
    }

    if (this.isAuthenticated) {
      if (localStorage["assistantShown"] === "false") {
        let returnURL =
          this.activatedRoute.snapshot.queryParams.returnUrl || null;

        if (returnURL) {
          returnURL = returnURL.split("?")[0];

          if (["/mapAds/settings/external-systems/link"].includes(returnURL)) {
            return true;
          }
        }

        localStorage["assistantShown"] = true;
        this.router.navigateByUrl("/onboarding");
        return false;
      }

      return true;
    }

    this.router.navigate(["/sessions/signin"], {
      queryParams: { returnUrl: state.url },
    });

    return false;
  }

  refreshToken(): Promise<any> {
    var tokenBody: URLSearchParams = new URLSearchParams();

    tokenBody.set("grant_type", "refresh_token");
    tokenBody.set("username", localStorage["username"]);
    tokenBody.set("refresh_token", localStorage["refreshtoken"]);
    tokenBody.set("client_id", CLIENT_ID);

    let options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
    };

    return this.httpclient
      .post<any>(
        environment.backendTokenUrl + "token",
        tokenBody.toString(),
        options
      )
      .toPromise();
  }
}
