import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import * as moment from "moment";
import { FacebookAdsPreviewComponent } from "../../dialogs/ads/facebook-ads-preview/facebook-ads-preview.component";

@Component({
  selector: "product-activities-timeline",
  templateUrl: "./product-activities-timeline.component.html",
  styleUrls: ["./product-activities-timeline.component.scss"],
})
export class ProductActivitiesTimelineComponent implements OnInit {
  @Input() activities: any;
  public sliderContainerWidth: number;
  public sliderContentWidth: number;
  public sliderItemWidth: number;
  public translateXSlider: number;
  public translateXMax: number;
  public translateXStep: number;
  public selectIndex: number;
  public circleValues: Array<any>;
  public selectSteps: number;
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    this.translateXMax = 0;
    this.translateXSlider = 0;
    this.sliderContentWidth = 0;
    this.sliderItemWidth = 0;
    this.translateXStep = 0;
    this.selectIndex = 0;
    this.selectSteps = 0;
    this.circleValues = [];
  }

  ngOnInit() {
    this.calcTimeDiffrence();
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function () {
      self.initSlider();
    }, 500);
    this.cdr.detectChanges();
  }

  @HostListener("window:resize", ["$event"])
  onHostResize(event) {
    const self = this;
    setTimeout(function () {
      self.circleValues = [];
      self.translateXSlider = 0;
      self.selectIndex = 0;
      self.initSlider();
    }, 500);
  }

  initSlider() {
    const container = document.getElementById("slider-container");

    const sliderItems = document.querySelectorAll(".activities-timeline-item");
    const containerWidth = container.clientWidth;
    this.sliderContainerWidth = containerWidth;

    sliderItems.forEach((sliderItem) => {
      (sliderItem as HTMLElement).style.width = `${
        document.body.clientWidth < 595 ? this.sliderContainerWidth : 140
      }px`;
    });

    if (sliderItems.length > 0) {
      this.sliderItemWidth = sliderItems[0].clientWidth;
    }

    const sliderContent = document.getElementById("slider-content");
    this.sliderContentWidth = sliderContent.scrollWidth;

    this.setMaxWidth();
    this.setStepWidth();
    this.setCircleValues();
  }

  setCircleValues() {
    this.selectSteps =
      Math.ceil(this.sliderContentWidth / this.translateXStep) - 1;

    for (let i = 0; i < this.selectSteps; i++) {
      this.circleValues.push([]);
    }
  }

  setMaxWidth() {
    this.translateXMax =
      this.sliderContentWidth - this.sliderContainerWidth < 0
        ? 0
        : this.sliderContentWidth - this.sliderContainerWidth;
  }

  setStepWidth() {
    this.translateXStep =
      this.sliderContainerWidth / 2 < this.sliderItemWidth
        ? this.sliderContainerWidth
        : this.sliderContainerWidth / 2;
  }

  calcTimeDiffrence() {
    for (let i = 0; i < this.activities.length; i++) {
      if (i + 1 === this.activities.length) {
        this.activities[i]["TimeDifference"] = 0;
      } else {
        const creationDate = moment(this.activities[i].CreationDate);
        const nextCreationDate = moment(this.activities[i + 1].CreationDate);
        let diffWeeks = creationDate.diff(nextCreationDate, "week");

        if (diffWeeks > 10) {
          diffWeeks = 10;
        } else if (diffWeeks === 0) {
          diffWeeks = 1;
        }

        this.activities[i]["TimeDifference"] = diffWeeks;
      }
    }
  }

  toIndex($event) {
    this.selectIndex = $event.index;
    const width = this.translateXStep * -1 * this.selectIndex;

    this.translateXSlider =
      width < this.translateXMax * -1
        ? this.translateXMax * -1
        : this.translateXStep * -1 * this.selectIndex;
  }

  next() {
    if (this.translateXSlider - this.translateXStep < this.translateXMax * -1) {
      this.translateXSlider = this.translateXMax * -1;
      this.selectIndex = this.selectSteps - 1;
    } else {
      this.translateXSlider -= this.translateXStep;
      this.selectIndex++;
    }
  }

  prev() {
    if (this.translateXSlider + this.translateXStep > 0) {
      this.translateXSlider = 0;
      this.selectIndex = 0;
    } else {
      this.translateXSlider += this.translateXStep;
      this.selectIndex--;
    }
  }

  onOpenPreview(adId: number) {
    this.dialog.open(FacebookAdsPreviewComponent, {
      width: "1000px",
      maxHeight: "95vh",
      data: {
        adId,
      },
    });
  }
}
