import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "app/shared/services/image.service";
import {
  base64ToByteArray,
  base64ToFile,
  blobToBase64,
  dataURLtoFile,
  toBase64,
} from "app/shared/utils/blob.utils";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "upload-image-dialog",
  templateUrl: "./upload-image-dialog.component.html",
  styleUrls: ["./upload-image-dialog.component.scss"],
})
export class UploadImageDialogComponent implements OnInit {
  public isLoading: boolean = false;
  public imageFile: any;
  public croppedImage: any;
  public backgroundRemovedImage: any;
  public showView: string; // IMAGE_CROPPER || BACKGROUND_REMOVER_ACTION || BACKGROUND_REMOVER_VALIDATE
  public showApplyBtn: boolean;
  public imagesForSelection: any;
  public selectedImage: string;
  public hasOriginTransparency: boolean = false;
  public backgroundRemovalTries: number = 0;

  constructor(
    private crudService: CrudService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<UploadImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.imageFile = this.data.file;

    const imageService = new ImageService(this.imageFile);
    this.hasOriginTransparency = !!(await imageService.isTransparent());

    if (this.data.useImageCropper) {
      this.showView = "IMAGE_CROPPER";
    } else if (this.data.useBackgroundRemover) {
      this.showView = "BACKGROUND_REMOVER_ACTION";
      this.isLoading = true;
      this.backgroundRemovalTries = await this.getBackgroundRemovalTries();
    }

    this.isLoading = false;
  }

  setCroppedImage(croppedImage) {
    this.croppedImage = croppedImage;
  }

  async onCrop() {
    const fileName = this.data.file.name;
    this.imageFile = dataURLtoFile(this.croppedImage, fileName);

    if (this.data.useBackgroundRemover && !this.hasOriginTransparency) {
      this.showView = "BACKGROUND_REMOVER_ACTION";
      this.isLoading = true;
      this.backgroundRemovalTries = await this.getBackgroundRemovalTries();
      this.isLoading = false;
    } else {
      this.dialogRef.close({ res: this.imageFile });
    }
  }

  onDecline() {
    this.dialogRef.close({ res: this.imageFile });
  }

  async onRemoveBackground() {
    this.showView = "BACKGROUND_REMOVER_VALIDATE";
    this.isLoading = true;

    this.backgroundRemovedImage = await this.removeImageBackground(
      this.imageFile
    );

    const originFileBase64 = await toBase64(this.imageFile);
    const backgroundRemoved = await blobToBase64(
      this.backgroundRemovedImage.body
    );

    this.backgroundRemovedImage = new File(
      [this.backgroundRemovedImage.body],
      this.imageFile.name,
      { type: "image/png" }
    );

    this.imagesForSelection = [
      {
        name: "ORIGIN",
        label: this.translate.instant("UneditedImage"),
        src: originFileBase64,
      },
      {
        name: "BACKGROUND_REMOVED",
        label: this.translate.instant("ImageWithoutBackground"),
        src: backgroundRemoved,
      },
    ];

    this.selectedImage = "BACKGROUND_REMOVED";
    this.isLoading = false;
  }

  onValidate() {
    let file;
    if (this.selectedImage === "BACKGROUND_REMOVED") {
      file = this.backgroundRemovedImage;
      file.isTransparent = true;
    } else {
      file = this.imageFile;
    }

    this.dialogRef.close({ res: file });
  }

  onSelectImage($event) {
    this.selectedImage = $event;
  }

  onClose() {
    this.dialogRef.close();
  }

  getBackgroundRemovalTries() {
    return this.crudService.getBackgroundRemovalTries().toPromise();
  }

  removeImageBackground(imageFile) {
    return this.crudService.removeImageBackground(imageFile).toPromise();
  }
}
