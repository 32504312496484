import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StoreChildModel } from "app/model/StoreChildModel";

@Component({
  selector: "store-detail-card",
  templateUrl: "./store-detail-card.component.html",
  styleUrls: ["./store-detail-card.component.scss"],
})
export class StoreDetailCardComponent implements OnInit {
  @Input() store: StoreChildModel;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
