import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "select-menu-item",
  templateUrl: "./select-menu-item.component.html",
  styleUrls: ["./select-menu-item.component.scss"],
})
export class SelectMenuItemComponent implements OnInit {
  @Input() label: string;
  @Input() icon?: string = null;
  @Input() isSelected: boolean;
  constructor() {}

  ngOnInit() {}
}
