import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ma-card-header",
  templateUrl: "./ma-card-header.component.html",
  styleUrls: ["./ma-card-header.component.scss"],
})
export class MaCardHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
