import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UserRecommendation } from "app/model/user/UserRecommendation";
import { LayoutService } from "app/shared/services/layout.service";

import { CrudService } from "app/views/mapAds/crud.service";
import { Subscription } from "rxjs";

@Component({
  selector: "tips",
  templateUrl: "./tips.component.html",
  styleUrls: ["./tips.component.scss"],
})
export class TipsComponent implements OnInit {
  @ViewChild("sliderContainer") sliderContainer: ElementRef;
  @ViewChild("sliderContent") sliderContent: ElementRef;
  @ViewChild("parentContainer") parentContainer: ElementRef;
  @Input() userRecommendations: UserRecommendation[];
  public sliderContainerWidth: number;
  public translateXSlider: number;
  public circleValues: Array<any> = [];
  public index: number = 0;
  public show: boolean = false;
  private layoutConfSub: Subscription;
  public layoutConf: any = {};
  public firstInit: boolean = false;
  constructor(private layout: LayoutService) {}

  async ngOnInit() {
    this.layoutConfSub = this.layout.layoutConf$.subscribe((layoutConf) => {
      this.layoutConf = layoutConf;
    });

    this.translateXSlider = 0;

    this.userRecommendations.forEach((tip, i) => {
      this.circleValues.push([]);
    });
  }

  ngOnDestroy() {
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function () {
      self.initSlider();
      self.play();
    }, 1000);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    const self = this;
    setTimeout(function () {
      self.initSlider();
    }, 500);
  }

  play() {
    setInterval(() => {
      this.next();
    }, 8000);
    this.show = true;
  }

  initSlider() {
    if (!this.firstInit) this.firstInit = true;

    const container = document.getElementById("slider-container");
    const sliderContent = document.getElementById("slider-content");
    const parent = document.getElementById("parent-container");
    const parentWidth = parent.clientWidth;

    container.style.width = this.layoutConf.isMobile
      ? `${parentWidth - 192}px`
      : `${parentWidth - 296}px`;

    const containerWidth = container.clientWidth;
    this.sliderContainerWidth = containerWidth;

    sliderContent.style.width = `${
      containerWidth * this.userRecommendations.length
    }px`;

    this.translateXSlider = 0;
    this.index = 0;
  }

  toIndex($event) {
    this.index = $event.index;
    this.translateXSlider = this.sliderContainerWidth * -1 * this.index;
  }

  next() {
    this.index =
      this.index < this.userRecommendations.length - 1 ? this.index + 1 : 0;

    this.translateXSlider = this.index * -1 * this.sliderContainerWidth;
  }

  prev() {
    this.index =
      this.index > 0 ? this.index - 1 : this.userRecommendations.length - 1;

    this.translateXSlider = this.index * -1 * this.sliderContainerWidth;
  }
}
