import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "add-new-action-message",
  templateUrl: "./add-new-action-message.component.html",
  styleUrls: ["./add-new-action-message.component.scss"],
})
export class AddNewActionMessageComponent implements OnInit {
  @Input() message: string;
  @Input() buttonLabel: string;
  @Input() action: string;
  constructor() {}

  ngOnInit() {}
}
