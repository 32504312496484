import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ma-icon-pill-list",
  templateUrl: "./ma-icon-pill-list.component.html",
  styleUrls: ["./ma-icon-pill-list.component.scss"],
})
export class MaIconPillListComponent implements OnInit {
  @Input() items: any;
  constructor() {}

  ngOnInit() {}
}
