import { Component, Input, OnInit } from "@angular/core";
import { fbAccount } from "app/model/account/fbAccount";
import { SubChannelSelection } from "app/model/channel/SubChannelSelection";
import { USER_TYPE_ID_MERCHANT } from "../../../../../constants";

@Component({
  selector: "channel-preferences-panel-grid",
  templateUrl: "./channel-preferences-panel-grid.component.html",
  styleUrls: ["./channel-preferences-panel-grid.component.scss"],
})
export class ChannelPreferencesPanelGridComponent implements OnInit {
  @Input() subChannelSelection: SubChannelSelection;
  @Input() youtubeStatus: any;
  @Input() facebookStatus: any;
  @Input() googleStatus: any;
  @Input() budgetSummary: any;
  @Input() facebookAccount: fbAccount;
  readonly USER_TYPE_ID_MERCHANT = USER_TYPE_ID_MERCHANT;
  readonly userTypeId = window.localStorage["userTypeId"];
  constructor() {}

  ngOnInit() {}
}
