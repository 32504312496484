import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FacebookAdsPreviewComponent } from "../../dialogs/ads/facebook-ads-preview/facebook-ads-preview.component";

@Component({
  selector: "weekly-channel-history-card",
  templateUrl: "./weekly-channel-history-card.component.html",
  styleUrls: ["./weekly-channel-history-card.component.scss"],
})
export class WeeklyChannelHistoryCardComponent implements OnInit {
  @Input() subChannelStatsWeekInfo: any;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  onOpenPreview(adId: number) {
    this.dialog.open(FacebookAdsPreviewComponent, {
      width: "1000px",
      maxHeight: "95vh",
      data: {
        adId,
      },
    });
  }
}
