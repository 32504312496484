import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/services/auth/auth.guard";
//import { LoginpageComponent } from './views/loginpage/loginpage.component';

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "mapAds/dashboard/main",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: "./views/sessions/sessions.module#SessionsModule",
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "mapAds",
        loadChildren: "./views/mapAds/mapAds.module#MapAdsModule",
        data: { title: "mapAds", breadcrumb: "MapAds" },
      },
    ],
  },
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "onboarding",
        loadChildren: "./views/onboarding/onboarding.module#OnboardingModule",
        data: { title: "Onboarding", breadcrumb: "Onboarding" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
