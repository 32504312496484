import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-create-promotion-dialog",
  templateUrl: "./create-promotion-dialog.component.html",
  styleUrls: ["./create-promotion-dialog.component.scss"],
})
export class CreatePromotionDialogComponent implements OnInit {
  public title: string;
  public subTitle: string;
  public showGTINForm: boolean;
  public icon: string;
  public formData: any;
  public messages: any;

  constructor(
    public dialogRef: MatDialogRef<CreatePromotionDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.messages = {};
  }

  ngOnInit() {
    this.formData = this.data;
    this.toggleGTINForm(this.data.isGTIN);
  }

  init() {
    this.setTitle();
    this.setSubTitle();
    this.setIcon();
  }

  toggleGTINForm(toggle: boolean) {
    this.showGTINForm = toggle;
    this.init();
  }

  setTitle() {
    this.title = this.showGTINForm
      ? this.translate.instant("createProductWithBarcode")
      : this.data.isNew
      ?  this.translate.instant("createProduct")
      : this.translate.instant("editProduct");
  }

  setSubTitle() {
    this.subTitle = this.showGTINForm
      ? this.translate.instant("createPromotionEnterGTIN")
      : this.data.isNew
      ? this.translate.instant("createPromotionEnterInfo")
      : this.translate.instant("createPromotionEditInfo");
  }

  setIcon() {
    this.icon = this.showGTINForm ? "document_scanner" : "shopping_basket";
  }

  onClose(): void {
    this.dialogRef.close();
  }

  mapProductData(data) {
    return {
      product_name: data.productName,
      product_description: data.productDescription,
      product_picture: data.productImageURL,
      imageIdx: data.productImageIds,
      product_price: data.price,
      product_size: 1,
      quantity: 1,
      unit: data.productUnitId,
      cate: data.productCategoryId,
      subCate: data.productCategorySubId,
      color: data.color,
      agegroup: data.agegroup,
      gender: data.gender,
      productImageURL: data.productImageURL,
      promotionImageURL: data.productImageURL,
      productImageUrls: data.productImageUrls,
      DisplayName: data.DisplayName,
      productGTIN_ExternalID: data.productGTIN_ExternalID,
      productGTIN: data.productGTIN,
    };
  }

  submitCreatePromotion($event): void {
    this.dialogRef.close({ res: $event.result });
  }

  submitSearchPromotion($event): void {
    this.formData.payload = this.mapProductData($event.result);

    if (
      this.formData.payload.productImageUrls !== null &&
      this.formData.payload.productImageUrls.length
    ) {
      this.messages.success = this.translate.instant("searchPromotionSuccess");
    } else {
      this.messages.error =
        this.translate.instant("searchPromotionFailure");
    }

    this.toggleGTINForm(false);
  }
}
