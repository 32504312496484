import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
})
export class AuthLayoutComponent implements OnInit {
  public scrollConfig = {};
  constructor() {}

  ngOnInit() {}
}
