export class FacebookCompactPagesModel {
  public access_token: string;
  public name: string;
  public id: string;

  public category: string;
  public category_list: string;
  public tasks: any;
}

export class FacebookStorePageTreeModel {
  public pageId: string;
  public pageName: string;
  public storeId: number;
  public storeName: string;
  public children: Array<FacebookStorePageTreeModel>;
}

export class FacebookUserModel {
  public id: string;
  public name: string;
}
export class FacebookPromotionRequestModel {
  public promoId: number;
}

export class FacebookCampaignModel {
  public name;
  public objective;
  public status;
  public special_ad_categories;
  public access_token;
}
export class FacebookAdvertisementActive {
  isactive: boolean = false;
}

// export class FacebookUserPermissionModel{
//     public name:String;
//     public isAssigned:boolean;
// }

export class InstagramModel {
  public id: string;
  public name: string;
}
export class FacebookPageInstaAccountMapping {
  facebook_page_id: string;
  facebook_page_name: string;
  public connected_instagram_account: InstagramModel;
}
export class FacebookPostModel {
  public postId: string;
  public productName: string;
  public postDate: Date;
  public postLikes: number;
  public postComments: number;
  public postShares: number;
  public postLink: string;
}

export class FacebookInsightsProductModel {
  public id: string;
  public productName: string;
  public clicks: number;
  public impressions: number;
  public date_start: Date;
  public date_stop: Date;
}
