import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "content-board",
  templateUrl: "./content-board.component.html",
  styleUrls: ["./content-board.component.scss"],
})
export class ContentBoardComponent implements OnInit {
  @Input() title: String;
  @Input() innerPadding: number = 3;
  @Input() img?: string;
  constructor() {}

  ngOnInit() {}
}
