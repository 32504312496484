import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AdPreview } from "app/model/AdPreview";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-facebook-ads-preview",
  templateUrl: "./facebook-ads-preview.component.html",
  styleUrls: ["./facebook-ads-preview.component.scss"],
})
export class FacebookAdsPreviewComponent implements OnInit {
  private adPreviews: AdPreview[];
  public isLoading: boolean;
  public iFrameLoading: boolean;
  public values: Array<string>;
  public index: number;
  constructor(
    public dialogRef: MatDialogRef<FacebookAdsPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService,
    private sanitizer: DomSanitizer
  ) {
    this.adPreviews = [];
    this.values = [];
    this.isLoading = true;
    this.iFrameLoading = true;
    this.index = 0;
  }

  ngOnInit() {
    this.getAdPreview(this.data.adId);
  }

  load() {
    this.iFrameLoading = false;
  }

  getAdPreview(adId: string) {
    this.crudService.GetFacebookAdPreviews(adId).subscribe((data) => {
      data.forEach((adPreview) => {
        const src = adPreview.IFrame.match('src="([a-zA-Z0-9:/._?=;\\-&]+)"');
        try {
          adPreview.Src = this.sanitizer.bypassSecurityTrustResourceUrl(
            src[1].replace("&amp;", "&")
          );
          this.adPreviews.push(adPreview);
          this.values.push(adPreview.Name);
        } catch {}
      });
      this.isLoading = false;
    });
  }

  onNext() {
    this.index += 1;
    this.iFrameLoading = true;
  }

  onBack() {
    this.index -= 1;
    this.iFrameLoading = true;
  }

  toIndex(value) {
    this.index = value.index;
    this.iFrameLoading = true;
  }

  onClose() {
    this.dialogRef.close();
  }
}
