import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "success-action-icon-message",
  templateUrl: "./success-action-icon-message.component.html",
  styleUrls: ["./success-action-icon-message.component.scss"],
})
export class SuccessActionIconMessageComponent implements OnInit {
  @Input() message: string;
  @Input() btnLabel: string;
  @Input() icon?: string = "check";
  @Input() background?: string = "background-white";
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
