import { Component, OnInit } from "@angular/core";

@Component({
  selector: "facebook-service-info-guide",
  templateUrl: "./facebook-service-info-guide.component.html",
  styleUrls: ["./facebook-service-info-guide.component.scss"],
})
export class FacebookServiceInfoGuideComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
