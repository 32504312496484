import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { StoreFlatNode } from "app/model/StoreFlatNode";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "create-group-form",
  templateUrl: "./create-group-form.component.html",
  styleUrls: ["./create-group-form.component.scss"],
})
export class CreateGroupFormComponent implements OnInit {
  @Output() afterSave: EventEmitter<any> = new EventEmitter(true);
  @ViewChild("form") form: NgForm;
  @Input() data: any;
  @Input() storeTree?: any;
  @Input() readonly?: boolean = false;
  public isLoading: boolean;
  public groupForm: FormGroup;
  public errors: object;
  public logoFile: any;
  public logoDemoImage: any;
  public backgroundImageFile: any;
  public backgroundImageDemoImage: any;
  public parentStoreId: number;
  public storeSelection: any;
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private crudService: CrudService,
    private loader: AppLoaderService
  ) {
    this.isLoading = false;
    this.errors = {};
    this.logoDemoImage = null;
    this.backgroundImageDemoImage = null;
    this.parentStoreId = +window.localStorage["storeId"];
    this.storeSelection = [];
  }

  async ngOnInit() {
    const items = this.data.payload;

    if (!this.data.isNew) {
      this.isLoading = true;
      this.storeTree = await this.getStoreTree(items.storeId);
      this.isLoading = false;
    }

    this.buildForm(items);
    this.setImages(items);
  }

  getStoreTree(vStoreId) {
    return this.crudService.getStoreTreeById(vStoreId).toPromise();
  }

  selectStoresRecursive(storeTree: any) {
    this.storeSelection.push(storeTree.StoreId);
    if (!storeTree.Children) {
      return;
    }

    storeTree.Children.forEach((child) => this.selectStoresRecursive(child));
  }

  selectAllStores(storeTree: any): any {
    this.deselectAllStores();
    this.selectStoresRecursive(storeTree);
  }

  deselectAllStores(): void {
    this.storeSelection = [];
  }

  receiveStoreSelection(storeSelection: SelectionModel<StoreFlatNode>) {
    this.setStoreSelection(storeSelection);
  }

  setStoreSelection(storeSelection: SelectionModel<StoreFlatNode>) {
    this.deselectAllStores();
    storeSelection.selected.forEach((item) => {
      this.storeSelection.push(item.StoreId);
    });
  }

  buildForm(items) {
    this.groupForm = this.fb.group({
      storeId: items.storeId || null,
      name: [items.name || "", Validators.required],
      description: items.description || "",
      imageIndex: items.imageIndex || null,
      backgroundImageIndex: items.backgroundImageIndex || null,
      childrenStores: [[]],
    });

    if (this.readonly) {
      this.groupForm.disable();
    }
  }

  setImages(items) {
    if (items.imageUrl) {
      this.logoDemoImage = items.imageUrl;
    } else {
      this.setDefaultLogoDemoImage();
    }

    if (items.backgroundImageUrl) {
      this.backgroundImageDemoImage = items.backgroundImageUrl;
    } else {
      this.setDefaultBackgroundDemoImage();
    }
  }

  setDefaultLogoDemoImage() {
    this.logoDemoImage =
      environment.imageServerUrl +
      "api/public/GetStoreImage?id=" +
      this.parentStoreId +
      "&v=" +
      new Date(); //Default image
  }

  setDefaultBackgroundDemoImage() {
    this.backgroundImageDemoImage =
      environment.imageServerUrl +
      "api/public/GetStoreBackground?id=" +
      this.parentStoreId +
      "&v=" +
      new Date(); //Default image
  }

  onLogoDroppedEvent($event) {
    const files: File[] = $event;
    this.logoFile = <File>$event[0];
    const reader = new FileReader();

    reader.onload = (_event) => {
      this.logoDemoImage = reader.result;
    };

    reader.readAsDataURL(files[0]);
  }

  onBackgroundImageDroppedEvent($event) {
    const files: File[] = $event;
    this.backgroundImageFile = <File>$event[0];
    const reader = new FileReader();

    reader.onload = (_event) => {
      this.backgroundImageDemoImage = reader.result;
    };

    reader.readAsDataURL(files[0]);
  }

  removeLogo() {
    this.logoFile = null;
    this.logoDemoImage = null;
    this.groupForm.controls["imageIndex"].setValue(null);
  }

  removeBackgroundImage() {
    this.backgroundImageFile = null;
    this.backgroundImageDemoImage = null;
    this.groupForm.controls["backgroundImageIndex"].setValue(null);
  }

  uploadImage(image) {
    if (!image) {
      return;
    }

    const myform = new FormData();
    myform.append("Upload_Image", image);

    return this.crudService.uploadStoreImage(myform).toPromise();
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.groupForm.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  async onSubmit() {
    this.markFormControls(this.groupForm.controls);

    // Form validation
    if (this.groupForm.invalid) {
      this.getValidationErrors();
      this.errors["global"] = this.translate.instant("createGroupFormFailure");

      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      return;
    }

    this.loader.open().subscribe(() => {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
    });

    if (this.data.isNew) {
      // Store selection validation
      if (this.storeSelection.length <= 0) {
        this.errors["global"] = this.translate.instant(
          "createPromoFormMinStoreFailure"
        );
        document.querySelector(".main-content-wrap").scrollTo(0, 0);
        this.loader.close();
        return;
      }
    }

    // Remove parentStoreId from selection (In case the user selects all stores)
    const parentStoreIdindex = this.storeSelection.indexOf(this.parentStoreId);
    if (parentStoreIdindex > -1) {
      this.storeSelection.splice(parentStoreIdindex, 1);
    }

    this.groupForm.get("childrenStores").setValue(this.storeSelection);

    // Upload Images
    try {
      if (this.logoFile) {
        const logoFileResponse = await this.uploadImage(this.logoFile);
        this.groupForm.controls["imageIndex"].setValue(logoFileResponse.body);
      }

      if (this.backgroundImageFile) {
        const backgroundFileResponse = await this.uploadImage(
          this.backgroundImageFile
        );
        this.groupForm.controls["backgroundImageIndex"].setValue(
          backgroundFileResponse.body
        );
      }
    } catch (e) {
      this.errors["global"] = e.error;
      this.loader.close();
      return;
    }
    // Makte request
    const value = this.groupForm.getRawValue();
    const request = this.data.isNew
      ? this.crudService.addVirtualStore(value)
      : this.crudService.updateStore(value);

    let success = true;

    const result = await request.toPromise().catch((e) => {
      this.errors["global"] = e.error;
      success = false;
    });

    this.loader.close();

    if (success) {
      this.afterSave.emit({ result: { success } });
    }
  }
}
