import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "vertical-divider",
  templateUrl: "./vertical-divider.component.html",
  styleUrls: ["./vertical-divider.component.scss"],
})
export class VerticalDividerComponent implements OnInit {
  @Input() name: string;
  constructor() {}

  ngOnInit() {}
}
