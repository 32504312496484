import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ma-multiple-cards",
  templateUrl: "./ma-multiple-cards.component.html",
  styleUrls: ["./ma-multiple-cards.component.scss"],
})
export class MaMultipleCardsComponent implements OnInit {
  @Input() width: any = null;
  constructor() {}

  ngOnInit() {}
}
