import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-external-system-successfully-connected-dialog",
  templateUrl: "./external-system-successfully-connected-dialog.component.html",
  styleUrls: ["./external-system-successfully-connected-dialog.component.scss"],
})
export class ExternalSystemSuccessfullyConnectedDialogComponent
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<ExternalSystemSuccessfullyConnectedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
