import { SERVICE_ID_SPARKASSE } from "../../src/constants";

export const environment = {
  serviceId: SERVICE_ID_SPARKASSE,
  production: false,
  appProvider: "S-Cashback",
  backendUrl: "https://x.mapads.net/internal2/api/",
  externalSystemsUrl: "https://testsystem.mapads.net/ExternalSystems/api/",
  googleUrl: "https://testsystem.mapads.net/Google/api/",
  facebookUrl: "https://testsystem.mapads.net/Facebook/api/",
  backendTokenUrl: "https://x.mapads.net/internal2/",
  imageServerUrl: "https://testsystem.mapads.net/ImageApi/",
  ownPartnerAppId: "c3ad0bce-2ac2-47ff-a99c-f291d51b143f",
};
