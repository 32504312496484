import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DOMRect } from "app/model/DOMRect";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent implements OnInit {
  @ViewChild("popover") ref: ElementRef;
  @Input() show: boolean;
  @Input() dirX?: string = "";
  public DOMRectPopover: DOMRect;
  public DOMRectParent: DOMRect;
  public popover: HTMLElement;
  public parent: HTMLElement;
  public arrow: HTMLElement;
  public spacing: number;

  constructor() {
    this.spacing = 12;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.popover = this.getPopoverElement();
    this.parent = this.getParentElement();
    this.arrow = this.getArrowElement();

    if (changes.show.currentValue) {
      this.updateDOMRect();
      this.resetPosition();
      this.updatePosition();
    }
  }

  updateDOMRect() {
    this.DOMRectPopover = this.getDOMRect(this.getPopoverElement());
    this.DOMRectParent = this.getDOMRect(this.getParentElement());
  }

  getDOMRect(element): DOMRect {
    return element.getBoundingClientRect();
  }

  resetPosition() {
    this.moveElementLeft(this.popover, 0);
    this.moveElementTop(
      this.popover,
      -(this.DOMRectPopover.height + this.spacing)
    );
    this.moveElementBottom(this.arrow, -17);
    this.moveElementTop(this.arrow, 0, true);
    this.rotateElement(this.arrow, 225);
  }

  updatePosition() {
    const direction = this.setDirection();
    const directionArr = direction.split(" ");

    if (this.dirX != "") {
      directionArr[1] = this.dirX;
    }

    if (directionArr[0] === "top") {
      this.moveElementTop(
        this.popover,
        -(this.DOMRectPopover.height + this.spacing)
      );
      this.moveElementBottom(this.arrow, -17);
      this.moveElementTop(this.arrow, 0, true);
      this.rotateElement(this.arrow, 225);
    } else {
      this.moveElementTop(
        this.popover,
        this.DOMRectParent.height + this.spacing
      );
      this.moveElementBottom(this.popover, 0, true);
      this.moveElementTop(this.arrow, 0);
      this.moveElementBottom(this.arrow, 0, true);
      this.rotateElement(this.arrow, 45);
    }

    this.fitWidthToScreen();

    if (directionArr[1] === "left") {
      this.moveElementLeft(
        this.popover,
        -this.DOMRectPopover.width + 2 * this.spacing
      );
      this.moveElementRight(this.arrow, this.spacing + 10);
      this.moveElementLeft(this.arrow, 0, true);
    } else {
      this.moveElementLeft(this.popover, 0);
      this.moveElementRight(this.arrow, 0, true);
      this.moveElementLeft(this.arrow, 10);
    }
  }

  moveElementTop(element: HTMLElement, value: number, auto: boolean = false) {
    this.moveElement(element, "top", value, auto);
  }

  moveElementRight(element: HTMLElement, value: number, auto: boolean = false) {
    this.moveElement(element, "right", value, auto);
  }

  moveElementBottom(
    element: HTMLElement,
    value: number,
    auto: boolean = false
  ) {
    this.moveElement(element, "bottom", value, auto);
  }

  moveElementLeft(element: HTMLElement, value: number, auto: boolean = false) {
    this.moveElement(element, "left", value, auto);
  }

  moveElement(
    element: HTMLElement,
    dir: string,
    value: number,
    auto: boolean = false
  ) {
    element.style[dir] = `${auto ? "auto" : value + "px"}`;
  }

  fitWidthToScreen() {
    if (this.checkOverflowX("right") && this.checkOverflowX("left")) {
      this.popover.style.width = document.body.clientWidth - 24 + "px";
    }
  }

  rotateElement(element: HTMLElement, deg: number) {
    element.style.transform = `translate(50%, -50%) rotate(${deg}deg)`;
  }

  setDirection() {
    let dirY = "top";
    let dirX = "right";

    if (this.checkOverflowY("top") && !this.checkOverflowY("bottom")) {
      dirY = "bottom";
    }

    if (this.checkOverflowX("right") && !this.checkOverflowX("left")) {
      dirX = "left";
    }

    return `${dirY} ${dirX}`;
  }

  checkOverflowY(dir: string) {
    return this.DOMRectParent[dir] - this.DOMRectPopover.height < 0;
  }

  checkOverflowX(dir: string) {
    return this.DOMRectParent[dir] - this.DOMRectPopover.width < 0;
  }

  getXOverflow(dir: string) {
    return this.DOMRectParent[dir] - this.DOMRectPopover.width;
  }

  getArrowElement(): HTMLElement {
    return this.ref.nativeElement.querySelector(".popover-arrow");
  }

  getPopoverElement(): HTMLElement {
    return this.ref.nativeElement;
  }

  getParentElement(): any {
    return this.getPopoverElement().parentNode.parentNode.parentNode;
  }
}
