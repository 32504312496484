import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTreeFlattener,
  MatTreeFlatDataSource,
  MatSnackBar,
} from "@angular/material";
import { FlatTreeControl } from "@angular/cdk/tree";
import { CrudService } from "../../crud.service";
import {
  FacebookAdvertisementActive,
  FacebookCompactPagesModel,
  FacebookPageInstaAccountMapping,
  FacebookStorePageTreeModel,
  FacebookUserModel,
} from "app/shared/models/FacebookServiceModels";
import { strictEqual } from "assert";
import { store } from "@angular/core/src/render3";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { FbService } from "../social-media/fb-service";
import { TranslateService } from "@ngx-translate/core";

interface TreeFlatNode {
  expandable: boolean;

  storeId: number;
  name: string;

  pageId: string;
  pageName: string;

  level: number;
}

@Component({
  selector: "app-facebook-page-connect-popup",
  templateUrl: "./facebook-page-connect-popup.component.html",
  styleUrls: ["./facebook-page-connect-popup.component.scss"],
})
export class FacebookPageConnectPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService,
    public dialogRef: MatDialogRef<any>,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private confirmService: AppConfirmService,
    public facebookService: FbService
  ) {
    this.closeLoader();
    this.myFacebookPages = this.data.pageData;
    this.facebookUser = this.data.userData;
    this.dataSource.data = [this.data.storeTreeData];
    this.myStoreTreeData = this.data.storeTreeData;

    this.treeControl.expandAll();
  }
  ngOnInit() {
    this.crudService.CheckLinkedInstagramAccount().subscribe(
      (data) => {
        this.isInstagramConnected = data;
        this.closeLoader();
      },
      (err) => {
        this.closeLoader();
        this.openSnackBar(
          this.translate.instant("instagramCouldNotLoad") + err.message,
          "OK"
        );
      }
    );

    this.crudService.GetLinkedInstagramAccounts().subscribe(
      (data) => {
        this.facebookInstagramMappings = data;
        this.closeLoader();
      },
      (err) => {
        this.closeLoader();
        this.openSnackBar(
          this.translate.instant("instagramCouldNotLoad") + err.message,
          "OK"
        );
      }
    );

    // this.crudService.GetFacebookAdsActiveStatus().subscribe(
    //   (data) => {
    //     this.adsActivationStatus = data;
    //     this.closeLoader();
    //   },
    //   (err) => {
    //     this.closeLoader();
    //     this.dialogRef.close();
    //     this.openSnackBar(
    //       "Facebook-Ads Status konnte nicht geladen werden: " + err.message,
    //       "OK"
    //     );
    //   }
    // );
    // this.facebookService.GetPermissions();
  }
  initCalls() {
    this.openLoader();
    this.crudService.GetMyFacebookPages().subscribe(
      (data) => {
        this.myFacebookPages = data;
        this.closeLoader();
      },
      (err) => {
        this.closeLoader();
        this.dialogRef.close();
        this.openSnackBar(
          this.translate.instant("facebookPagesCouldNotLoad") + err.message,
          "OK"
        );
      }
    );
    this.crudService.GetFacebookStorePageTree().subscribe(
      (data) => {
        this.dataSource.data = [data];
        this.myStoreTreeData = data;
        this.treeControl.expandAll();
        this.closeLoader();
      },
      (err) => {
        this.dialogRef.close();
        this.closeLoader();
        this.openSnackBar(
          this.translate.instant("facebookBusinessesCouldNotLoad") + err.message,
          "OK"
        );
      }
    );
    this.crudService.GetFacebookUserInformation().subscribe(
      (data) => {
        this.facebookUser = data;
        this.closeLoader();
      },
      (err) => {
        this.dialogRef.close();
        this.closeLoader();
        this.openSnackBar(
          this.translate.instant("userInfoCouldNotLoad") + err.message,
          "OK"
        );
      }
    );
  }
  public isInstagramConnected: boolean;
  public iGColumns: string[] = ["facebook_page", "instagram_acc"];
  public facebookInstagramMappings: FacebookPageInstaAccountMapping[];

  public facebookUser: FacebookUserModel;
  public myFacebookPages: FacebookCompactPagesModel[];
  public myStoreTreeData: FacebookStorePageTreeModel;

  public spinnerState = "spinner";
  public showSpinner = "spinner";
  public hideSpinner = "spinnerNone";

  public adsActivationStatus: FacebookAdvertisementActive = new FacebookAdvertisementActive();
  // public assignedPermissions: FacebookUserPermissionModel[] = [];

  private _transformer = (node: FacebookStorePageTreeModel, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,

      storeId: node.storeId,
      name: node.storeName,

      pageId: node.pageId,
      pageName: node.pageName,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<TreeFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: TreeFlatNode) => node.expandable;

  updateTreeValue(
    storeId: number,
    pageIdVal: string,
    myNode: FacebookStorePageTreeModel
  ) {
    if (myNode.storeId == storeId) {
      //update the value
      myNode.pageId = pageIdVal;
    } else if (myNode.pageId == pageIdVal) {
      //search for the required store in children
      myNode.pageId = null;
    }
    if (myNode.children)
      for (let i = 0; i < myNode.children.length; i++) {
        this.updateTreeValue(storeId, pageIdVal, myNode.children[i]);
      }

    this.dataSource.data = [this.myStoreTreeData];
    this.treeControl.expandAll();
  }

  onSelectChange(storeId, pageIdValue) {
    this.updateTreeValue(storeId, pageIdValue, this.myStoreTreeData);
  }
  public SyncUser() {
    this.openLoader();
    this.crudService.SyncFacebookUser().subscribe(
      (data) => {
        this.openSnackBar(this.translate.instant("dataUpdatedSuccessfully"), "OK");
        this.initCalls();
        this.closeLoader();
      },
      (err) => {
        this.closeLoader();
        this.openSnackBar(this.translate.instant("dataUpdatedfailure"), "OK");
      }
    );
  }
  submit() {
    // this.openLoader();
    // this.crudService
    //   .UpdateFacebookStorePageTree(this.myStoreTreeData)
    //   .subscribe(
    //     (data) => {
    //       this.closeLoader();
    //       this.openSnackBar("Ändeurngen übetragen", "OK");
    //       this.dialogRef.close();
    //     },
    //     (err) => {
    //       this.openSnackBar("Übertragung fehlgeschlagen: " + err.message, "OK");
    //       this.closeLoader();
    //     }
    //   );
  }

  // onAdsActivationStatusChange(checked: boolean) {
  //   // console.log(checked);
  //   // console.log(this.adsActivationStatus);
  //   let stat = checked ? "Aktivierung" : "Deaktivierung";
  //   this.confirmService
  //     .confirm({
  //       message: " Willst du wirklich die Aktion durchführen?",
  //       title: "Werbung De-/ aktivieren",
  //     })
  //     .subscribe((res) => {
  //       if (res) {
  //         this.openLoader();
  //         this.crudService
  //           .SetFacebookAdsActiveStatus(this.adsActivationStatus)
  //           .subscribe(
  //             (x) => {
  //               this.snackBar.open(stat + " erfolgreich", "OK");
  //               this.closeLoader();
  //             },
  //             (err) => {
  //               this.snackBar.open(
  //                 "Es ist ein Fehler aufgetreten: " + err.message,
  //                 "Ok"
  //               );
  //               this.closeLoader();
  //               this.adsActivationStatus.isactive = !this.adsActivationStatus
  //                 .isactive;
  //             }
  //           );
  //       } else {
  //         this.adsActivationStatus.isactive = !this.adsActivationStatus
  //           .isactive;
  //       }
  //     });
  // }
  onFacebookPopupPressed() {
    // this.facebookService.UpdateToken();
  }
  onClosing() {
    this.dialogRef.close(true);
  }
  disconnectFB() {
    this.confirmService
      .confirm({
        message: this.translate.instant("assistantFacebookAccountConnectDisconnectSure"),
        title:  this.translate.instant("FbDisconnect"),
      })
      .subscribe((res) => {
        if (res) {
          this.crudService.RemoveUser().subscribe(
            (data) => {
              this.openSnackBar(this.translate.instant("connectionDeleted"), "OK");
            },
            (err) => {
              this.openSnackBar(this.translate.instant("connectionNotDeleted"),
                "OK"
              );
            }
          );
          this.dialogRef.close(true);
        }
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  openLoader() {
    this.spinnerState = this.showSpinner;
  }
  closeLoader() {
    this.spinnerState = this.hideSpinner;
  }
}
