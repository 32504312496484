import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { USER_TYPE_ID_MERCHANT } from "../../../../../../constants";

@Component({
  selector: "app-promotion-preview-dialog",
  templateUrl: "./promotion-preview-dialog.component.html",
  styleUrls: ["./promotion-preview-dialog.component.scss"],
})
export class PromotionPreviewDialogComponent implements OnInit {
  public selection: String;
  public userTypeId: number;
  readonly USER_TYPE_ID_MERCHANT;

  constructor(
    public dialogRef: MatDialogRef<PromotionPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selection = "FACEBOOK";
    this.userTypeId = window.localStorage["userTypeId"];
    this.USER_TYPE_ID_MERCHANT = USER_TYPE_ID_MERCHANT;
  }

  ngOnInit() {}

  onChange(value) {
    this.selection = value;
  }

  onClose() {
    this.dialogRef.close();
  }
}
