import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ma-card-header-title",
  templateUrl: "./ma-card-header-title.component.html",
  styleUrls: ["./ma-card-header-title.component.scss"],
})
export class MaCardHeaderTitleComponent implements OnInit {
  @Input() title: string;
  @Output() onLearnMore?: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
