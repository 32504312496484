import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "success-animation-action-message",
  templateUrl: "./success-animation-action-message.component.html",
  styleUrls: ["./success-animation-action-message.component.scss"],
})
export class SuccessAnimationActionMessageComponent implements OnInit {
  @Input() message: string;
  @Input() btnLabel: string;
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
