import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getTemplateScreenshotById",
})
export class GetTemplateScreenshotByIdPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    let template = arg.find((item) => item.Id === value);

    return template ? template.Screenshot1 : "";
  }
}
