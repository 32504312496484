import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-no-images-uploaded-confirmation-dialog",
  templateUrl: "./no-images-uploaded-confirmation-dialog.component.html",
  styleUrls: ["./no-images-uploaded-confirmation-dialog.component.scss"],
})
export class NoImagesUploadedConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NoImagesUploadedConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose(proceed) {
    this.dialogRef.close({ proceed: proceed });
  }
}
