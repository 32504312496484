import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-facebook-preview',
  templateUrl: './facebook-preview.component.html',
  styleUrls: ['./facebook-preview.component.scss']
})
export class FacebookPreviewComponent implements OnInit {
  @Input() data: any
  public storeName: string = window.localStorage["storeName"];
  public storeImage: string = window.localStorage["StoreUrl"]

  constructor() { }

  ngOnInit() {
  }

}
