import { Component, OnInit } from "@angular/core";

@Component({
  selector: "content-box-label-value",
  templateUrl: "./content-box-label-value.component.html",
  styleUrls: ["./content-box-label-value.component.scss"],
})
export class ContentBoxLabelValueComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
