import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "step-slider",
  templateUrl: "./step-slider.component.html",
  styleUrls: ["./step-slider.component.scss"],
})
export class StepSliderComponent implements OnInit {
  @Input() max: number;
  @Input() min: number;
  @Input() stepSize: number;
  @Input() unit: string;
  @Input() value: number;
  @Output() inputChange: EventEmitter<any> = new EventEmitter();
  @Output() setValue: EventEmitter<any> = new EventEmitter();
  public steps: Array<any>;

  constructor() {
    this.steps = [];
  }

  ngOnInit() {
    this.setSteps();
  }

  setSteps() {
    const count = this.max / this.stepSize;

    for (let i = 0; i < count; i++) {
      let value = i * this.stepSize + this.min;

      this.steps.push({
        value,
        percentage: (100 / (count - 1)) * i - 100,
      });
    }
  }
}
