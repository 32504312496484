import { Component, Input, OnInit } from "@angular/core";
import { SubChannelDetails } from "app/model/channel/SubChannelDetails";

@Component({
  selector: "app-subchannel-card",
  templateUrl: "./subchannel-card.component.html",
  styleUrls: ["./subchannel-card.component.scss"],
})
export class SubchannelCardComponent implements OnInit {
  @Input() channel: SubChannelDetails;
  constructor() {}

  ngOnInit() {}
}
