import { Component, Input, OnInit } from "@angular/core";
import { SubChannelDetails } from "app/model/channel/SubChannelDetails";

@Component({
  selector: "app-subchannel-grid",
  templateUrl: "./subchannel-grid.component.html",
  styleUrls: ["./subchannel-grid.component.scss"],
})
export class SubchannelGridComponent implements OnInit {
  @Input() channels: SubChannelDetails[];
  constructor() {}

  ngOnInit() {}
}
