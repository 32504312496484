import { Component, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
  selector: "error-message-helper",
  template: `<small *ngIf="errorMessage !== null" class="form-error-msg">{{
    errorMessage
  }}</small>`,
})
export class ErrorMessageHelperComponent {
  @Input() control: FormControl;
  @Input() touchNotRequired: boolean;
  constructor(private translate: TranslateService) {}

  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        (this.control.touched || this.touchNotRequired)
      ) {
        return ValidationService.getValidatorErrorMessage(
          propertyName,
          this.translate,
          this.control.errors[propertyName]
        );
      }
    }

    return null;
  }
}
