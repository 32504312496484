import { Component, OnDestroy, OnInit } from "@angular/core";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { trigger, transition, animate, style } from "@angular/animations";

@Component({
  selector: "ma-snackbar",
  templateUrl: "./ma-snackbar.component.html",
  styleUrls: ["./ma-snackbar.component.scss"],
  animations: [
    trigger("state", [
      transition(":enter", [
        style({
          top: "300px",
          transform: "scale(0.3)",
        }),
        animate(
          "500ms cubic-bezier(0, 0, 0.2, 1)",
          style({
            transform: "scale(1)",
            opacity: 1,
            top: "50px",
          })
        ),
      ]),
      transition(":leave", [
        animate(
          "500ms cubic-bezier(0.4, 0.0, 1, 1)",
          style({
            transform: "scale(0.3)",
            opacity: 0,
            top: "100px",
          })
        ),
      ]),
    ]),
  ],
})
export class MaSnackbarComponent implements OnInit, OnDestroy {
  public show = false;
  public message: string = "";
  public type: string = "success";
  private snackbarSubscription: Subscription;

  constructor(private snackbarService: SnackbarService) {}

  ngOnInit() {
    this.snackbarSubscription = this.snackbarService.snackbarState.subscribe(
      (state) => {
        if (state.type) {
          this.type = state.type;
        } else {
          this.type = "success";
        }
        this.message = state.message;
        this.show = state.show;
        setTimeout(() => {
          this.show = false;
        }, 5000);
      }
    );
  }

  ngOnDestroy() {
    this.snackbarSubscription.unsubscribe();
  }
}
