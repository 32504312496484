import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { fbAccount } from "app/model/account/fbAccount";
import { ProductOverview } from "app/model/products/ProductOverview";
import { CurrencyService } from "app/shared/services/currency.service";

@Component({
  selector: "instagram-account-information",
  templateUrl: "./instagram-account-information.component.html",
  styleUrls: ["./instagram-account-information.component.scss"],
})
export class InstagramAccountInformationComponent implements OnInit {
  @Input() facebookAccount: fbAccount;
  @Input() productOverview: ProductOverview;
  @Input() budgetSummary: any;
  @Input() instagramAds: boolean;
  @Input() instagramVideoAds: boolean;
  public AtLeast100BudgetForAdvertising: string;
  public AtLeast300BudgetForVideoAdvertising: string;
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.AtLeast100BudgetForAdvertising = this.translateService.instant(
      "AtLeastBudgetForAdvertising",
      { amount: CurrencyService.formatNumber2Price(100) }
    );

    this.AtLeast300BudgetForVideoAdvertising = this.translateService.instant(
      "AtLeastBudgetForVideoAdvertising",
      { amount: CurrencyService.formatNumber2Price(300) }
    );
  }
}
