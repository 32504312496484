import { Platform } from "@angular/cdk/platform";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-allow-browser-popup-instructions",
  templateUrl: "./allow-browser-popup-instructions.component.html",
  styleUrls: ["./allow-browser-popup-instructions.component.scss"],
})
export class AllowBrowserPopupInstructionsComponent implements OnInit {
  constructor(public platform: Platform) {}

  ngOnInit() {}
}
