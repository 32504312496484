import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "selected-video-template",
  templateUrl: "./selected-video-template.component.html",
  styleUrls: ["./selected-video-template.component.scss"],
})
export class SelectedVideoTemplateComponent implements OnInit {
  @Input() videoTemplate: any;
  @Input() selectedThemeId: number;
  @Output() onSelectThemeId: EventEmitter<any> = new EventEmitter(true);
  public activeScreenshot: string;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.activeScreenshot = this.videoTemplate.Screenshot1;
  }

  ngOnInit() {}

  onSelectScreenshot(index) {
    switch (index) {
      case 1:
        this.activeScreenshot = this.videoTemplate.Screenshot1;
        break;
      case 2:
        this.activeScreenshot = this.videoTemplate.Screenshot2;
        break;
      case 3:
        this.activeScreenshot = this.videoTemplate.Screenshot3;
        break;
      default:
        break;
    }
  }
}
