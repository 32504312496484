import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "review-your-video-template-dialog",
  templateUrl: "./review-your-video-template-dialog.component.html",
  styleUrls: ["./review-your-video-template-dialog.component.scss"],
})
export class ReviewYourVideoTemplateDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ReviewYourVideoTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onRedirect() {
    this.dialogRef.close({ redirect: true });
  }
}
