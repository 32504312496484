import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "app/shared/services/validation.service";
import { CorporateColorSelectorDialogComponent } from "../corporate-color-selector-dialog/corporate-color-selector-dialog.component";

@Component({
  selector: "app-video-template-selector-dialog",
  templateUrl: "./video-template-selector-dialog.component.html",
  styleUrls: ["./video-template-selector-dialog.component.scss"],
})
export class VideoTemplateSelectorDialogComponent implements OnInit {
  public selectedTemplateId: number = null;
  public selectedThemeId: number = null;
  public selectedTemplate: any;
  public selectedPrimaryColor: string;
  public selectedSecondaryColor: string;
  public errors: object;
  public form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<VideoTemplateSelectorDialogComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.errors = {};

    const templateInformation = this.data.templateInformation;
    this.selectedTemplateId = templateInformation.templateId;
    this.selectedThemeId = !templateInformation.theme
      ? null
      : templateInformation.theme.themeId;
    this.selectedPrimaryColor = templateInformation.primaryColor
      ? templateInformation.primaryColor
      : this.data.corporateColors === null
      ? null
      : this.data.corporateColors.PrimaryColor;
    this.selectedSecondaryColor = templateInformation.secondaryColor
      ? templateInformation.secondaryColor
      : this.data.corporateColors === null
      ? null
      : this.data.corporateColors.SecondaryColor;

    this.selectedTemplate = this.data.videoTemplates.find(
      (videoTemplate) => videoTemplate.Id === this.selectedTemplateId
    );
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      slogan1: [
        this.data.templateInformation.slogan_1,
        [Validators.required, Validators.maxLength(70)],
      ],
      slogan2: [
        this.data.templateInformation.slogan_2,
        Validators.maxLength(70),
      ],
    });
  }

  openCorporateColorSelector(storeId: number, index: string) {
    let color =
      index === "primary"
        ? this.selectedPrimaryColor
        : this.selectedSecondaryColor;

    const dialogRef = this.dialog.open(CorporateColorSelectorDialogComponent, {
      data: {
        storeId: storeId,
        color: color,
      },
    });

    dialogRef.beforeClosed().subscribe((res) => {
      if (res) {
        const rgb = res.res;

        if (index === "primary") {
          this.selectedPrimaryColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`;
        } else {
          this.selectedSecondaryColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`;
        }
      }
    });
  }

  onSelectVideoTemplateId($event) {
    this.selectedTemplateId = $event;
    this.selectedTemplate = this.data.videoTemplates.find(
      (videoTemplate) => videoTemplate.Id === this.selectedTemplateId
    );

    this.selectedThemeId =
      !this.selectedTemplate.themes || !this.selectedTemplate.themes.length
        ? null
        : this.selectedTemplate.themes[0].themeId;
  }

  onSelectThemeId($event) {
    this.selectedThemeId = $event;
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.form.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  onApply() {
    this.markFormControls(this.form.controls);

    if (this.form.invalid) {
      this.getValidationErrors();

      return;
    }

    const templateInformation = this.data.templateInformation;
    const selectedTheme = !this.selectedThemeId
      ? null
      : this.selectedTemplate.themes.find(
          (theme) => theme.themeId === this.selectedThemeId
        );

    const result = {
      templateId: this.selectedTemplateId,
      templateDetailsId: templateInformation.templateDetailsId,
      storeId: templateInformation.storeId,
      slogan_1: this.form.get("slogan1").value,
      slogan_2: this.form.get("slogan2").value,
      productCount_desktop: templateInformation.productCount_desktop,
      productCount_mobile: templateInformation.productCount_mobile,
      duration_desktop: templateInformation.duration_desktop,
      duration_mobile: templateInformation.duration_mobile,
      theme: selectedTheme,
      primaryColor: this.selectedPrimaryColor,
      secondaryColor: this.selectedSecondaryColor,
    };

    this.dialogRef.close({
      result,
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
