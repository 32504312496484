import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';

import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit {
  searchForm = new FormGroup({
    store: new FormControl(''),
    search_start_date: new FormControl(''),
    search_end_date: new FormControl(''),
    search_term: new FormControl(''),
    location: new FormControl(''),
  });
  constructor() { }

  ngOnInit() {
  }
  submit(){
    console.warn(this.searchForm.value);

  }

}
