import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "color-box",
  templateUrl: "./color-box.component.html",
  styleUrls: ["./color-box.component.scss"],
})
export class ColorBoxComponent implements OnInit {
  @Input() color: string;
  @Input() size?: number = 8;
  constructor() {}

  ngOnInit() {}
}
