import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { ExternalSystem } from "app/views/mapAds/external-systems/external-systems.component";
import { externalSystemsConfig } from "../../../../../views/mapAds/external-systems/external-system-config";

@Component({
  selector: "add-external-system-form",
  templateUrl: "./add-external-system-form.component.html",
  styleUrls: ["./add-external-system-form.component.scss"],
})
export class AddExternalSystemFormComponent implements OnInit {
  @ViewChild("form") form: NgForm;
  @Output() linkExternalConnection: EventEmitter<any> = new EventEmitter();
  @Input() externalSystem: ExternalSystem;
  public externalSystemConfig: any;
  public externalSystemForm: FormGroup;
  public errors: Object;
  public inputs: any;
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private snack: MatSnackBar,
    private crudService: CrudService
  ) {
    this.errors = {};
  }

  ngOnInit() {
    this.externalSystemConfig = this.getExternalSystemConfig();
    this.buildstoreForm();
  }

  buildstoreForm() {
    let group = {};
    this.inputs = this.getFormInputs();

    this.inputs.forEach((input) => {
      const validators = [Validators.required];

      if (input.name === "CustomEndpoint") {
        validators.push(ValidationService.urlValidator);
      }

      group[input.name] = ["", validators];
    });

    this.externalSystemForm = this.fb.group(group);
  }

  getExternalSystemConfig() {
    return externalSystemsConfig.find(
      (system) => system.name === this.externalSystem.Name
    );
  }

  getFormInputs() {
    return this.externalSystemConfig.properties.filter(
      (property) => property.input
    );
  }

  getFormValidationErrors(): Object {
    const errors = {};
    Object.keys(this.externalSystemForm.controls).forEach((key) => {
      const control = this.externalSystemForm.get(key);
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];
        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });
    return errors;
  }

  submit() {
    this.form.ngSubmit.emit();
    this.errors = this.getFormValidationErrors();

    if (this.externalSystemForm.invalid) {
      this.snack.open(this.translate.instant("fillAllInfo"), "OK", {
        duration: 4000,
      });
      return;
    }
    this.externalSystemForm.value.ExternalSystemId = this.externalSystem.Id;

    this.linkExternalConnection.emit({
      value: this.externalSystemForm.value,
      func: this.externalSystemConfig.func,
    });
  }
}
