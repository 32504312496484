import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-popup-blocked-dialog",
  templateUrl: "./popup-blocked-dialog.component.html",
  styleUrls: ["./popup-blocked-dialog.component.scss"],
})
export class PopupBlockedDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PopupBlockedDialogComponent>) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
