import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ma-card",
  templateUrl: "./ma-card.component.html",
  styleUrls: ["./ma-card.component.scss"],
})
export class MaCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
