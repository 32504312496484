import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "ma-accordion",
  templateUrl: "./ma-accordion.component.html",
  styleUrls: ["./ma-accordion.component.scss"],
})
export class MaAccordionComponent implements OnInit {
  @Input() title: string;
  @Input() open: boolean = false;
  public isOpen: boolean;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("open")) {
      this.isOpen = changes.open.currentValue;
    }
  }
}
