import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { fbAccount } from "app/model/account/fbAccount";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { FbService } from "app/views/mapAds/settings/social-media/fb-service";

@Component({
  selector: "facebook-account-information",
  templateUrl: "./facebook-account-information.component.html",
  styleUrls: ["./facebook-account-information.component.scss"],
})
export class FacebookAccountInformationComponent implements OnInit {
  @Input() facebookAccount: fbAccount;
  public status: String[];
  public statusIndex: number = 0;
  public isLoading: boolean = false;
  public facebookMapping: any = null;
  public pages: any = null;
  public stores: any = null;
  public pageStoreMapping: any;
  constructor(
    private router: Router,
    private crudService: CrudService,
    private translate: TranslateService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private confirmService: AppConfirmService,
    private facebookService: FbService
  ) {}

  ngOnInit() {
    this.status = [
      this.translate.instant("ConnectFacebookAccount"),
      this.translate.instant("AssignFacebookPages"),
      this.translate.instant("SendRequests"),
      this.translate.instant("AcceptRequest"),
      this.translate.instant("CreatProductsWithProductImages"),
      this.translate.instant("61"),
    ];

    if (!this.facebookAccount.PageNotLinked) {
      this.getFacebookMapping();
      this.getPages();
      this.getStores();
    }

    if (this.facebookAccount.NeedsUpdate) {
      this.status[0] = this.translate.instant("UpdateAccount");
    }

    this.genStatusIndex();
  }

  setPageStoreMapping() {
    const data = [];

    this.facebookMapping.forEach((mapping) => {
      let store = this.stores.find(
        (store) => store.storeId === mapping.storeId
      );
      let page = this.pages.find((page) => page.id === mapping.pageId);

      if (page && store) {
        data.push({ store, page });
      }
    });

    this.pageStoreMapping = data;
  }

  editFacebookMapping() {
    this.router.navigateByUrl(
      `/onboarding?step=FACEBOOK&substep=FACEBOOK_ACCOUNT_SELECT_PAGES&singleStep=true`
    );
  }

  async removeFacebookUser() {
    this.confirmService
      .confirm({
        message: this.translate.instant("deleteFacebookConnectionConfirmation"),
        title: this.translate.instant(
          "deleteFacebookConnectionConfirmationTitle"
        ),
      })
      .subscribe(async (res) => {
        if (res) {
          this.appLoader.open();
          await this.crudService
            .RemoveUser()
            .toPromise()
            .then((res) => {
              this.appLoader.close();

              this.reloadCurrentRoute();
              this.snackbarService.show(
                this.translate.instant("FBAccountSuccessfullyDisconnected"),
                "success"
              );
            })
            .catch((e) => {
              this.snackbarService.show(e.error, "danger");
              this.appLoader.close();
            });
        }
      });
  }

  async updateFacebookUser() {
    this.appLoader.open();
    this.isLoading = true;
    await this.facebookService
      .login()
      .then((res) => {
        this.appLoader.close();
        this.isLoading = false;
        this.reloadCurrentRoute();
        this.snackbarService.show(
          this.translate.instant("FBAccountSuccessfullyRefreshed"),
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e, "danger");
        this.appLoader.close();
        this.isLoading = false;
      });
  }

  getPages() {
    this.crudService.GetMyFacebookPages().subscribe((res) => {
      this.pages = res;
      if (this.facebookMapping && this.stores) {
        this.setPageStoreMapping();
      }
    });
  }

  getStores() {
    this.crudService.getVirtualStores().subscribe((res) => {
      this.stores = res;
      if (this.facebookMapping && this.pages) {
        this.setPageStoreMapping();
      }
    });
  }

  getFacebookMapping() {
    this.crudService.GetStorePageMapping().subscribe((res) => {
      this.facebookMapping = res;

      if (this.stores && this.pages) {
        this.setPageStoreMapping();
      }
    });
  }

  genStatusIndex() {
    if (!this.facebookAccount.Email || this.facebookAccount.NeedsUpdate) {
      this.statusIndex = 0;
      return;
    }

    if (this.facebookAccount.PageNotLinked) {
      this.statusIndex = 1;
      return;
    }

    if (
      (this.facebookAccount.LinkedPages === null ||
        this.facebookAccount.LinkedPages.length === 0) &&
      this.facebookAccount.Status == 2
    ) {
      this.statusIndex = 2;
      return;
    }

    if (
      this.facebookAccount.Status == 2 &&
      this.facebookAccount.LinkedPages.length > 0
    ) {
      this.statusIndex = 3;
      return;
    }

    this.statusIndex = 5;
  }

  setup() {
    const step = "FACEBOOK";
    let subStep = "";

    switch (this.statusIndex) {
      case 0:
        subStep = "FACEBOOK_ACCOUNT_CONNECT";
        break;
      case 1:
        subStep = "FACEBOOK_ACCOUNT_SELECT_PAGES";
        break;
      case 2:
        subStep = "FACEBOOK_ACCOUNT_SEND_REQUEST";
        break;
      case 3:
        subStep = "FACEBOOK_ACCOUNT_SEND_REQUEST";
        break;
    }

    this.router.navigateByUrl(`/onboarding?step=${step}&substep=${subStep}`);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  createProduct() {
    this.router.navigate(["/mapAds/promo/create"], {
      queryParams: { new: true, gtin: false },
    });
  }
}
