export class UserVideoTemplate {
  Id: string;
  Name: string;
  Link: string;
  IsNew: boolean;
  Screenshot1: string;
  Screenshot2: string;
  Screenshot3: string;
  Themes?: UserVideoTemplate[];
}
