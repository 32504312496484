import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "success-icon-message",
  templateUrl: "./success-icon-message.component.html",
  styleUrls: ["./success-icon-message.component.scss"],
})
export class SuccessIconMessageComponent implements OnInit {
  @Input() message: string;
  constructor() {}

  ngOnInit() {}
}
