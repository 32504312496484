import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ma-card-content",
  templateUrl: "./ma-card-content.component.html",
  styleUrls: ["./ma-card-content.component.scss"],
})
export class MaCardContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
