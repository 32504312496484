import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ma-shape-circle",
  templateUrl: "./ma-shape-circle.component.html",
  styleUrls: ["./ma-shape-circle.component.scss"],
})
export class MaShapeCircleComponent implements OnInit {
  @Input() size: number;
  @Input() color: string;
  @Input() opacity: number = 1;

  constructor() {}

  ngOnInit() {}
}
