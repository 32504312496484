import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ma-rounded-basic-logo-light",
  templateUrl: "./ma-rounded-basic-logo-light.component.html",
  styleUrls: ["./ma-rounded-basic-logo-light.component.scss"],
})
export class MaRoundedBasicLogoLightComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
