import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-status-select-circles",
  templateUrl: "./status-select-circles.component.html",
  styleUrls: ["./status-select-circles.component.scss"],
})
export class StatusSelectCirclesComponent implements OnInit {
  @Input() values: Array<any>;
  @Input() index: number;
  @Output() toIndex?: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  onSetIndex(index) {
    this.toIndex.emit(index);
  }
}
