import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "impressions-difference",
  templateUrl: "./impressions-difference.component.html",
  styleUrls: ["./impressions-difference.component.scss"],
})
export class ImpressionsDifferenceComponent implements OnInit {
  @Input() value: number;
  @Input() trend: number;
  constructor() {}

  ngOnInit() {}
}
