import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ma-shape-triangle",
  templateUrl: "./ma-shape-triangle.component.html",
  styleUrls: ["./ma-shape-triangle.component.scss"],
})
export class MaShapeTriangleComponent implements OnInit {
  @Input() size: number;
  @Input() opacity: number;
  @Input() color: string;
  @Input() rotate: number = 0;
  constructor() {}

  ngOnInit() {}
}
