import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ma-background-shapes-dark",
  templateUrl: "./ma-background-shapes-dark.component.html",
  styleUrls: ["./ma-background-shapes-dark.component.scss"],
})
export class MaBackgroundShapesDarkComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
