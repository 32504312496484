import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "content-box-title",
  templateUrl: "./content-box-title.component.html",
  styleUrls: ["./content-box-title.component.scss"],
})
export class ContentBoxTitleComponent implements OnInit {
  @Input() title: string;
  @Input() size?: number = 5;
  constructor() {}

  ngOnInit() {}
}
