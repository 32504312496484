import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "inline-icon-message",
  templateUrl: "./inline-icon-message.component.html",
  styleUrls: ["./inline-icon-message.component.scss"],
})
export class InlineIconMessageComponent implements OnInit {
  @Input() type: string;
  @Input() icon?: string = "info_outline";
  @Input() message: string;
  constructor() {}

  ngOnInit() {}
}
