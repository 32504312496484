import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "inline-action-icon-message",
  templateUrl: "./inline-action-icon-message.component.html",
  styleUrls: ["./inline-action-icon-message.component.scss"],
})
export class InlineActionIconMessageComponent implements OnInit {
  @Input() message: string;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() btnColor?: string = "basic";
  @Input() btnLabel: string;
  @Input() btnIcon?: string;
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
