import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatSidenav, MatDialog } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { ChatService } from './notifications.service';





@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']

})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;
  isMobile;
  screenSizeWatcher: Subscription;
  isSidenavOpen: Boolean = true;
  @ViewChild(MatSidenav) public sideNav: MatSidenav;

  activeChatUser = {
    name: 'Gevorg Spartak',
    photo: 'assets/images/face-2.jpg',
    isOnline: true,
    lastMsg: 'Hello!'
  };
  user;


  // Dummy notifications (which are turned off at the moment)
  notifications = [{
  message: 'New contact added',
    icon: 'assignment_ind',
    time: '1 min ago',
    route: '/inbox',
    color: 'primary'
  }, {
    message: 'New message',
    icon: 'chat',
    time: '4 min ago',
    route: '/chat',
    color: 'accent'
  }, {
    message: 'Server rebooted',
    icon: 'settings_backup_restore',
    time: '12 min ago',
    route: '/charts',
    color: 'warn'
  }]

  // END DUMMY NOTIFICATIONS

  constructor(
    private router: Router, 
    private media: ObservableMedia, 
    public chatService: ChatService
  ) {
      //console.log(chatService.chats)
      this.user = chatService.user

    }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.notificPanel.close();
        }
    });
    this.chatSideBarInit();
  }

  ngOnDestroy() {
    if (this.screenSizeWatcher) {
      this.screenSizeWatcher.unsubscribe();
    }}

    changeActiveUser(user) {
      this.activeChatUser = user;
    }

    updateSidenav() {
      var self = this;
      setTimeout(() => {
        self.isSidenavOpen = !self.isMobile;
        if( self.sideNav != null)
          self.sideNav.mode = self.isMobile ? 'over' : 'side';
      });
    }

    chatSideBarInit() {
      this.isMobile = this.media.isActive('xs') || this.media.isActive('sm');
      this.updateSidenav();
      this.screenSizeWatcher = this.media.subscribe((change: MediaChange) => {
        this.isMobile = (change.mqAlias === 'xs') || (change.mqAlias === 'sm');
        this.updateSidenav();
      });
    }






  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }
}
