import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animation-checkmark-circle',
  templateUrl: './animation-checkmark-circle.component.html',
  styleUrls: ['./animation-checkmark-circle.component.scss']
})
export class AnimationCheckmarkCircleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
