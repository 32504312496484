import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ma-message",
  templateUrl: "./ma-message.component.html",
  styleUrls: ["./ma-message.component.scss"],
})
export class MaMessageComponent implements OnInit {
  @Input() message: string = "";
  @Input() type: string = "success";
  constructor() {}

  ngOnInit() {}
}
