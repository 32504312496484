import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "info-action-message",
  templateUrl: "./info-action-message.component.html",
  styleUrls: ["./info-action-message.component.scss"],
})
export class InfoActionMessageComponent implements OnInit {
  @Input() title?: string = "";
  @Input() message: string;
  @Input() icon?: string = "info";
  @Input() btnLabel: string;
  @Input() btnColor?: string = "basic";
  @Output() action: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
