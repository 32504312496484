import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ma-card-collapse-content",
  templateUrl: "./ma-card-collapse-content.component.html",
  styleUrls: ["./ma-card-collapse-content.component.scss"],
})
export class MaCardCollapseContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
