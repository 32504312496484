import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "content-box-item-label",
  templateUrl: "./content-box-item-label.component.html",
  styleUrls: ["./content-box-item-label.component.scss"],
})
export class ContentBoxItemLabelComponent implements OnInit {
  @Input() label: string;
  @Input() caption?: string;
  constructor() {}

  ngOnInit() {}
}
