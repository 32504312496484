import { Platform } from "@angular/cdk/platform";
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from "@angular/core";
import { NativeDateAdapter } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { DATE_FORMAT_GERMAN, DATE_FORMAT_ENGLISH } from "../../../constants";
import * as moment from "moment";

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(LOCALE_ID) public locale,
    @Inject(PLATFORM_ID) platformId,
    private translate: TranslateService
  ) {
    super(locale, platformId);
  }

  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf(".") > -1) {
      const str = value.split(".");

      let year = Number(str[2]);
      let month = Number(str[1]) - 1;
      let date = Number(str[0]);

      if (year <= 99) {
        year += 2000;
      } else if (year >= 100 && year <= 999) {
        year = Number(year.toString() + "0");
      }

      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: string): string {
    let dateFormat =
      this.translate.currentLang === "de"
        ? DATE_FORMAT_GERMAN
        : DATE_FORMAT_ENGLISH;
    if (displayFormat === "input") {
      return moment(date).format(dateFormat);
    } else {
      return moment(date).format(displayFormat);
    }
  }
}
