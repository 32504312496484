import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ranking-chart",
  templateUrl: "./ranking-chart.component.html",
  styleUrls: ["./ranking-chart.component.scss"],
})
export class RankingChartComponent implements OnInit {
  @Input() ranking: any;
  public max: number;
  constructor() {
    this.max = 0;
  }

  ngOnInit() {
    this.setMaxValue();
    this.calcPercentage();
    this.sortRanking();
  }

  sortRanking() {
    this.ranking.sort(this.compare);
  }

  compare(a, b) {
    if (a.value < b.value) {
      return 1;
    }
    if (a.value > b.value) {
      return -1;
    }
    return 0;
  }

  setMaxValue() {
    this.ranking.forEach((item) => {
      if (item.value > this.max) {
        this.max = item.value;
      }
    });
  }

  calcPercentage() {
    this.ranking.forEach((item) => {
      let percentage = (item.value / this.max) * 100;
      item.percentage = percentage === 0 ? 1 : percentage;
    });
  }
}
