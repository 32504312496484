import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "view-action-box",
  templateUrl: "./view-action-box.component.html",
  styleUrls: ["./view-action-box.component.scss"],
})
export class ViewActionBoxComponent implements OnInit {
  @Input() selectedItem: any;
  @Input() readonly?: boolean = false;
  @Output() onEdit?: EventEmitter<any> = new EventEmitter();
  @Output() onDelete?: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
