import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { googleAccount } from "app/model/account/googleAccount";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "google-account-information",
  templateUrl: "./google-account-information.component.html",
  styleUrls: ["./google-account-information.component.scss"],
})
export class GoogleAccountInformationComponent implements OnInit {
  @Input() googleAccount: googleAccount;
  public status: String[];
  public statusIndex: number = 0;
  public isLoading: boolean = false;
  readonly environment = environment;
  constructor(private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.status = [
      this.translate.instant("CreateGoogleAccount"),
      this.translate.instant("GrantPermissions"),
      this.translate.instant("CreateAtLeast15ProductsWithBarcode"),
      this.translate.instant("InventoryCheckIsRequested"),
      this.translate.instant("InventoryCheckIsPerformed"),
      this.translate.instant("61"),
    ];

    this.genStatusIndex();
  }

  genStatusIndex() {
    //completed
    if (this.googleAccount.Status === 3) {
      this.statusIndex = 5;
      return;
    }

    //need GMB access
    if (this.googleAccount.Status === 1) {
      this.statusIndex = 1;
      return;
    }

    //we have GMB access
    if (this.googleAccount.Status === 2) {
      if (this.googleAccount.IsLFP) {
        //auto
        if (this.googleAccount.ProdsWithGTIN < 15) {
          //more prods
          this.statusIndex = 2;
          return;
        } else {
          //if we have prods --> auto inventory
          this.statusIndex = 4;
          return;
        }
      } else {
        //manuell
        if (this.googleAccount.TotalProdCount < 15) {
          //TODO: should we check??
          this.statusIndex = 2;
          return;
        } else {
          if (this.googleAccount.InventoryStatus >= 1) {
            //check will take place
            this.statusIndex = 4;
          } else {
            //will be started
            this.statusIndex = 3;
          }

          return;
        }
      }
    }

    this.statusIndex = 0;
  }

  setup() {
    const step = "GOOGLE";
    let subStep = "";

    switch (this.statusIndex) {
      case 0:
        subStep = "GOOGLE_ACCOUNT_CREATED";
        break;
      case 1:
        subStep = "GOOGLE_REQUEST_PERMISSION";
        break;
    }

    this.router.navigateByUrl(`/onboarding?step=${step}&substep=${subStep}`);
  }

  createProductWithBarcode() {
    this.router.navigate(["/mapAds/promo/create"], {
      queryParams: { new: true, gtin: true },
    });
  }
}
